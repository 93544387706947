<template>
    <v-form ref="form"
            v-model="valid"
            :readonly="pendingSave"
            lazy-validation>
        <v-container v-if="loaded">
            <div class="form-section">
                <component :is="getStaticComponent('StaticArticleFormHeader')"></component>
            </div>
            <div class="shadow-divider shadow-divider--up"></div>






            <!------------------- Content type & exclusive right ------------------->
            <div class="form-section">
                <div class="form-section-title">
                    <p class="static-text-heading-3">Indlæggets type</p>

                    <v-dialog v-model="dialogHelpContentTypes"
                              v-bind="dialogProps"
                              :fullscreen="$vuetify.breakpoint.xs">
                        <template v-slot:activator="{ on, attrs }">
                            <a href="#"
                               v-bind="attrs"
                               v-on="on"
                               @click.stop.prevent>Læs om de forskellige typer af indlæg</a>
                        </template>

                        <v-card>
                            <v-card-title>
                                Typer af debatindlæg
                            </v-card-title>
                            <v-card-text>
                                <div v-for="contentType in contentTypes"
                                     :key="contentType.name">
                                    <strong v-text="getContentTypeDisplayName(contentType)"></strong>
                                    <p v-text="contentType.description"></p>
                                </div>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn v-bind="buttonProps"
                                       @click="dialogHelpContentTypes = false">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <v-row no-gutters>
                    <v-col>
                        <label class="v-label"
                               for="articleContentType">*Type af indlæg</label>
                        <v-autocomplete v-model="currentContentType"
                                        return-object
                                        v-bind="inputProps"
                                        :items="contentTypes"
                                        :item-text="getContentTypeDisplayName"
                                        :rules="getRequiredValidationRule()"
                                        id="articleContentType"
                                        placeholder="Vælg fra listen"
                                        append-icon="mdi-chevron-down"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row no-gutters
                       v-if="fieldsVisibility.enableExclusiveRight">
                    <v-col>
                        <v-checkbox v-model="article.exclusiveRight"
                                    v-bind="inputProps"
                                    :rules="getRequiredValidationRule()">
                            <template v-slot:label>
                                <component :is="getStaticComponent('StaticArticleFormAcceptExclusiveRight')"></component>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </div>
            <v-divider class="form-section-divider"></v-divider>






            <!------------------- Sender ------------------->
            <div class="form-section">
                <div class="form-section-title">
                    <p class="static-text-heading-3">Indsenders oplysninger (offentliggøres ikke)</p>
                    <a href="https://medielogin.dk/mit/medielogin"
                       target="_blank">Ret oplysningerne i Medielogin</a>
                    <span class="mx-2">|</span>
                    <v-dialog v-model="dialogHelpSender"
                              v-bind="dialogProps"
                              :fullscreen="$vuetify.breakpoint.xs">
                        <template v-slot:activator="{ on, attrs }">
                            <a href="#"
                               v-bind="attrs"
                               v-on="on"
                               @click.stop.prevent>Hvad bruges oplysningerne til?</a>
                        </template>

                        <v-card>
                            <v-card-title>
                                Hvad bruges indsenders oplysninger til?
                            </v-card-title>
                            <v-card-text>
                                <component :is="getStaticComponent('StaticArticleFormSenderInfo')"></component>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn v-bind="buttonProps"
                                       @click="dialogHelpSender = false">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <v-row no-gutters>
                    <v-col>
                        <label for="senderName">Navn</label>
                        <v-text-field :value="article.sender.name"
                                      readonly
                                      v-bind="inputProps"
                                      id="senderName"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <label for="senderEmail">*E-mailadresse</label>
                        <v-text-field :value="article.sender.email"
                                      readonly
                                      v-bind="inputProps"
                                      :rules="getRequiredValidationRule()"
                                      id="senderEmail"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <label for="senderPhone">*Telefonnummer</label>
                        <v-text-field v-model="article.sender.phone"
                                      v-bind="inputProps"
                                      :rules="getTextValidationRules(senderFieldsRequirements.phone)"
                                      :counter="getCounterValue(article.sender.phone, senderFieldsRequirements.phone)"
                                      id="senderPhone">
                            <template v-slot:counter="{ props }">
                                <span v-text="getCounterText(props)"
                                      class="counter"></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <v-divider class="form-section-divider"></v-divider>






            <!------------------- Authors ------------------->
            <div class="form-section">
                <div class="form-section-title">
                    <p class="static-text-heading-3">Om debattøren (den, der har skrevet indlægget)</p>

                    <v-dialog v-model="dialogHelpAuthor"
                              v-bind="dialogProps"
                              :fullscreen="$vuetify.breakpoint.xs">
                        <template v-slot:activator="{ on, attrs }">
                            <a href="#"
                               v-bind="attrs"
                               v-on="on"
                               @click.stop.prevent>Hvad bruges oplysningerne til?</a>
                        </template>

                        <v-card>
                            <v-card-title>
                                Hvad bruges debattørens oplysninger til?
                            </v-card-title>
                            <v-card-text>
                                <component :is="getStaticComponent('StaticArticleFormAuthorInfo')"></component>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn v-bind="buttonProps"
                                       @click="dialogHelpAuthor = false">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <v-slide-y-transition :group="true">
                    <div v-for="(author, index) in article.authors"
                         :key="index"
                         :index="index">

                        <div class="author-title">
                            <span v-text="'Debattør ' + (index + 1)"
                                  class="author-title-text"></span>
                            <template v-if="index > 0">
                                <span class="mx-2">|</span>
                                <a href="#"
                                   @click.prevent="removeAuthor(index)"
                                   :class="getDisabledLinkClass()">- Slet</a>
                            </template>
                        </div>
                        <v-row no-gutters>
                            <v-col>
                                <label :for="'authorName' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.name"></label>
                                <v-textarea v-model="author.name"
                                            v-bind="inputProps"
                                            :rules="getTextValidationRules(authorFieldsRequirements.name)"
                                            :counter="getCounterValue(author.name, authorFieldsRequirements.name)"
                                            :id="'authorName' + index"
                                            autoGrow
                                            rows="1">
                                    <template v-slot:counter="{ props }">
                                        <span v-text="getCounterText(props)"
                                              class="counter"></span>
                                    </template>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row no-gutters
                               v-if="fieldsVisibility.enableAuthorProfession">
                            <v-col>
                                <label :for="'authorProfession' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.profession"></label>
                                <v-textarea v-model="author.profession"
                                            v-bind="inputProps"
                                            :rules="getTextValidationRules(authorFieldsRequirements.profession)"
                                            :counter="getCounterValue(author.profession, authorFieldsRequirements.profession)"
                                            :id="'authorProfession' + index"
                                            autoGrow
                                            rows="1">
                                    <template v-slot:counter="{ props }">
                                        <span v-text="getCounterText(props)"
                                              class="counter"></span>
                                    </template>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row no-gutters
                               v-if="(fieldsVisibility.enableAuthorZipCode || fieldsVisibility.enableAuthorCity) && index === 0">
                            <v-col v-if="fieldsVisibility.enableAuthorZipCode"
                                   cols="12"
                                   sm="5"
                                   class="pr-sm-3">
                                <label :for="'authorZipCode' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.zipCode"></label>
                                <v-text-field v-model="author.zipCode"
                                              v-bind="inputProps"
                                              :rules="getTextValidationRules(authorFieldsRequirements.zipCode)"
                                              :counter="getCounterValue(author.zipCode, authorFieldsRequirements.zipCode)"
                                              :id="'authorZipCode' + index">
                                    <template v-slot:counter="{ props }">
                                        <span v-text="getCounterText(props)"
                                              class="counter"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-if="fieldsVisibility.enableAuthorCity"
                                   cols="12"
                                   sm="7"
                                   class="pl-sm-3">
                                <label :for="'authorCity' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.city"></label>
                                <v-text-field v-model="author.city"
                                              v-bind="inputProps"
                                              :rules="getTextValidationRules(authorFieldsRequirements.city)"
                                              :counter="getCounterValue(author.city, authorFieldsRequirements.city)"
                                              :id="'authorCity' + index">
                                    <template v-slot:counter="{ props }">
                                        <span v-text="getCounterText(props)"
                                              class="counter"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters
                               v-if="fieldsVisibility.enableAuthorAddress && index === 0">
                            <v-col>
                                <label :for="'authorAddress' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.address"></label>
                                <v-text-field v-model="author.address"
                                              v-bind="inputProps"
                                              :rules="getTextValidationRules(authorFieldsRequirements.address)"
                                              :counter="getCounterValue(author.address, authorFieldsRequirements.address)"
                                              :id="'authorAddress' + index">
                                    <template v-slot:counter="{ props }">
                                        <span v-text="getCounterText(props)"
                                              class="counter"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters
                               v-if="fieldsVisibility.enableAuthorGender && index === 0">
                            <v-col>
                                <label :for="'authorGender' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.gender"></label>
                                <v-autocomplete v-model="author.gender"
                                                v-bind="inputProps"
                                                :items="genders"
                                                item-text="name"
                                                item-value="name"
                                                :rules="getRequiredValidationRule()"
                                                :id="'authorGender' + index"
                                                placeholder="Vælg fra listen"
                                                append-icon="mdi-chevron-down"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters
                               v-if="fieldsVisibility.enableAuthorBirthDate && index === 0">
                            <v-col>
                                <label :for="'authorAge' + index"
                                       v-text="mediaSetup.debateAuthorFieldLabels.birthDate"></label>
                                <v-number-input :value="getAuthorBirthYear(author)"
                                                @input="setAuthorBirthYear(author, $event)"
                                                :min="1900"
                                                :max="9999"
                                                v-bind="inputProps"
                                                :rules="getRequiredValidationRule()"
                                                :id="'authorAge' + index"></v-number-input>

                            </v-col>
                        </v-row>
                    </div>
                </v-slide-y-transition>

                <a v-if="article.authors.length < businessSetup.maxAuthors"
                   href="#"
                   @click.prevent="addAuthor"
                   :class="getDisabledLinkClass()">+ Tilføj en debattør</a>
            </div>
            <v-divider class="form-section-divider"></v-divider>






            <!------------------- Article fields ------------------->
            <div class="form-section">
                <div class="form-section-title">
                    <p class="static-text-heading-3">Indlægget</p>
                </div>

                <v-row no-gutters>
                    <v-col>
                        <label for="articleTitle">*Forslag til overskrift</label>
                        <v-textarea v-model="article.title"
                                    v-bind="textAreaProps"
                                    :rules="getTextValidationRules(articleFieldsRequirements.title)"
                                    :counter="getCounterValue(article.title, articleFieldsRequirements.title)"
                                    id="articleTitle">
                            <template v-slot:counter="{ props }">
                                <span v-text="getCounterText(props)"
                                      class="counter"></span>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters
                       v-if="articleFieldsRequirements.subtitle.visible">
                    <v-col>
                        <label for="articleSubtitle"><span v-if="articleFieldsRequirements.subtitle.required">*</span>Forslag til underoverskrift (valgfri)</label>
                        <v-textarea v-model="article.subtitle"
                                    v-bind="textAreaProps"
                                    :rules="getTextValidationRules(articleFieldsRequirements.subtitle)"
                                    :counter="getCounterValue(article.subtitle, articleFieldsRequirements.subtitle)"
                                    rows="3"
                                    id="articleSubtitle">
                            <template v-slot:counter="{ props }">
                                <span v-text="getCounterText(props)"
                                      class="counter"></span>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <label for="articleText">*Indlæg</label>
                        <v-html-input v-model="article.text"
                                      v-bind="inputProps"
                                      :rules="getArticleTextValidationRules(articleFieldsRequirements.text, plainArticleText)"
                                      :counter="getCounterValue(plainArticleText, articleFieldsRequirements.text, true)"
                                      :counter-value="plainArticleText !== null ? plainArticleText.length: 0"
                                      id="articleText"
                                      class="v-input--article-text"
                                      link-dialog-enabled
                                      :link-dialog-button-props="buttonProps">
                            <template v-slot:counter="{ props }">
                                <span v-text="getCounterText(props)"
                                      class="counter"></span>
                            </template>
                        </v-html-input>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <component :is="getStaticComponent('StaticArticleFormArticleTextInfo')"></component>
                    </v-col>
                </v-row>
            </div>
            




            <!------------------- Other article fields ------------------->
            <template v-if="articleFieldsRequirements.summary.visible || articleFieldsRequirements.comment.visible || fieldsVisibility.enableFileUpload">
                <v-divider class="form-section-divider"></v-divider>
                <div class="form-section">

                    <div v-if="articleFieldsRequirements.summary.visible || articleFieldsRequirements.comment.visible"
                         class="form-section-title">
                        <p class="static-text-heading-3">Om indlægget</p>
                    </div>

                    <v-row no-gutters
                           v-if="articleFieldsRequirements.summary.visible">
                        <v-col>
                            <label for="articleSummary"><span v-if="articleFieldsRequirements.summary.required">*</span>Mit budskab i indlægget er</label>
                            <v-textarea v-model="article.summary"
                                        v-bind="textAreaProps"
                                        :rules="getTextValidationRules(articleFieldsRequirements.summary)"
                                        :counter="getCounterValue(article.summary, articleFieldsRequirements.summary)"
                                        rows="5"
                                        id="articleSummary">
                                <template v-slot:counter="{ props }">
                                    <span v-text="getCounterText(props)"
                                          class="counter"></span>
                                </template>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters
                           v-if="articleFieldsRequirements.comment.visible">
                        <v-col>
                            <label for="articleComment"><span v-if="articleFieldsRequirements.comment.required">*</span>Er der noget, redaktionen bør vide om dig eller indlægget?</label>
                            <v-textarea v-model="article.comment"
                                        v-bind="textAreaProps"
                                        :rules="getTextValidationRules(articleFieldsRequirements.comment)"
                                        :counter="getCounterValue(article.comment, articleFieldsRequirements.comment)"
                                        rows="5"
                                        id="articleComment">
                                <template v-slot:counter="{ props }">
                                    <span v-text="getCounterText(props)"
                                          class="counter"></span>
                                </template>
                            </v-textarea>
                        </v-col>
                    </v-row>


                    <div v-if="fieldsVisibility.enableFileUpload"
                         class="form-section-title">
                        <p class="static-text-heading-3"
                           v-text="`Filer (max. ${Math.floor(maxFileSize / 1000000)}MB per fil)`"></p>
                    </div>
                    <v-row no-gutters
                           v-if="fieldsVisibility.enableFileUpload">
                        <v-col>
                            <v-slide-y-transition :group="true">
                                <div v-for="(file, index) in files"
                                     :key="index"
                                     :index="index">
                                    <div class="author-title">
                                        <span v-text="'#' + (index + 1)"
                                              :class="['author-title-text', file.size > maxFileSize ? 'error--text' : null]">
                                        </span>
                                        <span class="mx-2">|</span>
                                        <a href="#"
                                           @click.prevent="removeItemFromArray(files, index)"
                                           :class="getDisabledLinkClass()">- Slet</a>
                                    </div>

                                    <v-row no-gutters>
                                        <v-col>
                                            <span v-text="file.name"
                                                  :class="file.size > maxFileSize ? 'error--text' : null"></span>
                                            <span v-text="formatFileSize(file)"
                                                  class="ml-4"></span>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters
                                           v-if="isImageFile(file) && fieldsVisibility.enableImageCaption">
                                        <v-col>
                                            <label :for="'imageCaption' + index">Billedtekst</label>
                                            <v-textarea v-model="file.caption"
                                                        v-bind="textAreaProps"
                                                        :rules="getTextValidationRules(imageFieldsRequirements.caption)"
                                                        :id="'imageCaption' + index"
                                                        rows="1"
                                                        :maxlength="imageFieldsRequirements.caption.max">
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters
                                           v-if="isImageFile(file) && fieldsVisibility.enableImagePhotographer">
                                        <v-col>
                                            <label :for="'imagePhotographer' + index">Fotograf</label>
                                            <v-text-field v-model="file.photographer"
                                                          v-bind="inputProps"
                                                          :rules="getTextValidationRules(imageFieldsRequirements.photographer)"
                                                          :id="'imagePhotographer' + index"
                                                          :maxlength="imageFieldsRequirements.photographer.max">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters
                                           v-if="isImageFile(file) && fieldsVisibility.enableImageCopyright">
                                        <v-col>
                                            <label :for="'imageCopyright' + index">Copyright</label>
                                            <v-text-field v-model="file.copyright"
                                                          v-bind="inputProps"
                                                          :rules="getTextValidationRules(imageFieldsRequirements.copyright)"
                                                          :id="'imageCopyright' + index"
                                                          :maxlength="imageFieldsRequirements.copyright.max">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-slide-y-transition>
                            <div v-if="files.length < maxFiles">
                                <input type="file"
                                       ref="fileInput"
                                       class="d-none"
                                       accept=".doc,.docx,.odt,.rtf,.txt,.pdf,.bmp,.gif,.jpg,.jpeg,.jpe,.png,.psd,.tif,.tiff"
                                       multiple
                                       @change="addFiles(Array.from($refs.fileInput.files)); $refs.fileInput.value = ''" />
                                <a href="#"
                                   @click.prevent="pendingSave ? null : $refs.fileInput.click()"
                                   :class="getDisabledLinkClass()">+ Vælg</a>
                            </div>
                        </v-col>
                    </v-row>

                </div>
            </template>


            <div class="shadow-divider shadow-divider--down"></div>






            <!------------------- Confirmation and send ------------------->
            <div class="form-section">
                <v-row no-gutters>
                    <v-col>
                        <v-checkbox v-model="acceptPersonalInformation"
                                    v-bind="inputProps"
                                    :rules="getRequiredValidationRule('For at indsende et debatindlæg skal du give samtykke til, at vi må behandle dine persondata.')">
                            <template v-slot:label>
                                <component :is="getStaticComponent('StaticArticleFormAcceptPersonalInformation')"></component>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-checkbox v-model="acceptContact"
                                    v-bind="inputProps"
                                    :rules="getRequiredValidationRule('For at indsende et debatindlæg skal du give samtykke til, at vi må kontakte dig.')">
                            <template v-slot:label>
                                <component :is="getStaticComponent('StaticArticleFormAcceptContact')"></component>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row no-gutters
                       v-if="fieldsVisibility.enableUnderage">
                    <v-col>
                        <label class="v-label"
                               for="articleUnderage">*Er en eller flere af indlæggets skribenter under 18 år?</label>
                        <v-autocomplete v-model="article.underage"
                                        v-bind="inputProps"
                                        :items="[{text: 'Ja', value: true}, {text: 'Nej', value: false}]"
                                        :rules="getRequiredValidationRule(null, true)"
                                        id="articleUnderage"
                                        placeholder="Vælg fra listen"
                                        append-icon="mdi-chevron-down"></v-autocomplete>
                    </v-col>
                </v-row>


                <v-row no-gutters>
                    <v-col>
                        <div class="form-section-actions">
                            <v-btn @click="submit()"
                                   v-bind="buttonProps"
                                   :loading="pendingSave">Indsend mit indlæg</v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-row no-gutters
                       v-if="!valid">
                    <v-col>
                        <v-alert dense
                                 type="error">
                            <span v-text="validationMessageCommon"></span>
                        </v-alert>
                    </v-col>
                </v-row>
                
                <v-row no-gutters
                       v-if="saveError">
                    <v-col>
                        <v-alert dense
                                 type="error">
                            <span v-text="saveError"></span>
                        </v-alert>
                    </v-col>
                </v-row>
            </div>
            <v-divider class="form-section-divider"></v-divider>

            <div class="form-section">
                <component :is="getStaticComponent('StaticArticleFormFooter')"></component>
            </div>
        </v-container>
    </v-form>
</template>

<script>
    import api from "../../api"
    import { RouteName } from "../../enums"

    import jyllandsPostenStaticArticleFormHeader from "../../branding/jp/jyllands-posten/components/VStaticArticleFormHeader"
    import jyllandsPostenStaticArticleFormAcceptExclusiveRight from "../../branding/jp/jyllands-posten/components/VStaticArticleFormAcceptExclusiveRight"
    import jyllandsPostenStaticArticleFormSenderInfo from "../../branding/jp/jyllands-posten/components/VStaticArticleFormSenderInfo"
    import jyllandsPostenStaticArticleFormAuthorInfo from "../../branding/jp/jyllands-posten/components/VStaticArticleFormAuthorInfo"
    import jyllandsPostenStaticArticleFormArticleTextInfo from "../../branding/jp/jyllands-posten/components/VStaticArticleFormArticleTextInfo"
    import jyllandsPostenStaticArticleFormAcceptPersonalInformation from "../../branding/jp/jyllands-posten/components/VStaticArticleFormAcceptPersonalInformation"
    import jyllandsPostenStaticArticleFormAcceptContact from "../../branding/jp/jyllands-posten/components/VStaticArticleFormAcceptContact"
    import jyllandsPostenStaticArticleFormFooter from "../../branding/jp/jyllands-posten/components/VStaticArticleFormFooter"

    import finansStaticArticleFormHeader from "../../branding/jp/finans/components/VStaticArticleFormHeader"
    import finansStaticArticleFormAcceptExclusiveRight from "../../branding/jp/finans/components/VStaticArticleFormAcceptExclusiveRight"
    import finansStaticArticleFormSenderInfo from "../../branding/jp/finans/components/VStaticArticleFormSenderInfo"
    import finansStaticArticleFormAuthorInfo from "../../branding/jp/finans/components/VStaticArticleFormAuthorInfo"
    import finansStaticArticleFormArticleTextInfo from "../../branding/jp/finans/components/VStaticArticleFormArticleTextInfo"
    import finansStaticArticleFormAcceptPersonalInformation from "../../branding/jp/finans/components/VStaticArticleFormAcceptPersonalInformation"
    import finansStaticArticleFormAcceptContact from "../../branding/jp/finans/components/VStaticArticleFormAcceptContact"
    import finansStaticArticleFormFooter from "../../branding/jp/finans/components/VStaticArticleFormFooter"

    import politikenStaticArticleFormHeader from "../../branding/politiken/components/VStaticArticleFormHeader"
    import politikenStaticArticleFormAcceptExclusiveRight from "../../branding/politiken/components/VStaticArticleFormAcceptExclusiveRight"
    import politikenStaticArticleFormSenderInfo from "../../branding/politiken/components/VStaticArticleFormSenderInfo"
    import politikenStaticArticleFormAuthorInfo from "../../branding/politiken/components/VStaticArticleFormAuthorInfo"
    import politikenStaticArticleFormArticleTextInfo from "../../branding/politiken/components/VStaticArticleFormArticleTextInfo"
    import politikenStaticArticleFormAcceptPersonalInformation from "../../branding/politiken/components/VStaticArticleFormAcceptPersonalInformation"
    import politikenStaticArticleFormAcceptContact from "../../branding/politiken/components/VStaticArticleFormAcceptContact"
    import politikenStaticArticleFormFooter from "../../branding/politiken/components/VStaticArticleFormFooter"

    import ArticleFormCore from "../../mixins/articleFormCore"

    export default {
        components: {
            jyllandsPostenStaticArticleFormHeader,
            jyllandsPostenStaticArticleFormAcceptExclusiveRight,
            jyllandsPostenStaticArticleFormSenderInfo,
            jyllandsPostenStaticArticleFormAuthorInfo,
            jyllandsPostenStaticArticleFormArticleTextInfo,
            jyllandsPostenStaticArticleFormAcceptPersonalInformation,
            jyllandsPostenStaticArticleFormAcceptContact,
            jyllandsPostenStaticArticleFormFooter,

            finansStaticArticleFormHeader,
            finansStaticArticleFormAcceptExclusiveRight,
            finansStaticArticleFormSenderInfo,
            finansStaticArticleFormAuthorInfo,
            finansStaticArticleFormArticleTextInfo,
            finansStaticArticleFormAcceptPersonalInformation,
            finansStaticArticleFormAcceptContact,
            finansStaticArticleFormFooter,

            politikenStaticArticleFormHeader,
            politikenStaticArticleFormAcceptExclusiveRight,
            politikenStaticArticleFormSenderInfo,
            politikenStaticArticleFormAuthorInfo,
            politikenStaticArticleFormArticleTextInfo,
            politikenStaticArticleFormAcceptPersonalInformation,
            politikenStaticArticleFormAcceptContact,
            politikenStaticArticleFormFooter
        },
        mixins: [ArticleFormCore],
        props: {
            mediaName: {
                type: String,
                required: true
            },
            mediaSetup: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialogHelpContentTypes: false,
                dialogHelpSender: false,
                dialogHelpAuthor: false,
                acceptPersonalInformation: false,
                acceptContact: false
            }
        },
        computed: {
            inputProps() { return this.mediaSetup.vuetify.inputProps },
            textAreaProps() { return this.mediaSetup.vuetify.textAreaProps },
            dialogProps() { return this.mediaSetup.vuetify.dialogProps },
            buttonProps() { return this.mediaSetup.vuetify.buttonProps }
        },
        methods: {
            async load() {
                this.contentTypes = (await api.getMedia(this.businessCode, this.mediaName)).contentTypes;
                this.genders = await api.getGenders();
                this.businessSetup = await api.getBusinessSetup(this.businessCode);
                this.loaded = true;
                if (this.contentTypes.length === 1)
                    this.currentContentType = this.contentTypes[0];
            },
            getStaticComponent(componentName) {
                return this.mediaName === null ? null : this.mediaName + componentName;
            },
            scrollToFirstError() {
                this.$nextTick(() => {
                    const errElement = this.$refs.form.$el.querySelector(".error--text");
                    const rowElement = errElement?.closest(".row");
                    if (rowElement)
                        this.$vuetify.goTo(rowElement);
                    else if (errElement)
                        this.$vuetify.goTo(errElement);
                });
            },
            navigateToArticleSentView(uid) {
                this.$router.push({ name: RouteName.ArticleSent, params: { uid: uid } });
            }
        },
        created() {
            this.load();
            this.$on("validation-failed", this.scrollToFirstError);
            this.$on("article-created", this.navigateToArticleSentView);
        },
        beforeDestroy() {
            this.$off("validation-failed", this.scrollToFirstError);
            this.$off("article-created", this.navigateToArticleSentView);
        }
    }
</script>