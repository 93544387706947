import api from "../api"

export default {
    debug: true,
    state: {
        account: null
    },
    async loadAccount() {
        if (this.state.account !== null)
            return Promise.resolve(true);
        this.state.account = await api.getAccount();
        return Promise.resolve(true);
    }
}
