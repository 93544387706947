<template>
    <div>
        <v-tabs v-model="tab"
                grow>
            <v-tab v-text="$t('chat')"></v-tab>
            <v-tab v-text="$t('article')"></v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab"
                      class="mt-8">
            <v-tab-item>
                <v-chat :article-id="uid"
                        :notification-hub="notificationHub"
                        :media-setup="mediaSetup"></v-chat>
            </v-tab-item>
            <v-tab-item>
                <div class="form-section">
                    <v-article-info :uid="uid"
                                    :business-code="businessCode"
                                    :media-setup="mediaSetup"
                                    :article-source="articleSource"
                                    :notification-hub="notificationHub"
                                    enable-file-upload></v-article-info>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import connection from "../signalr";

    import VChat from "../components/VChat";
    import VArticleInfo from "../components/VArticleInfo";

    export default {
        components: {
            VChat,
            VArticleInfo,
        },
        props: {
            account: Object,
            businessCode: String,
            mediaSetup: Object,
            uid: String,
            articleSource: String
        },
        data() {
            return {
                tab: 0,
                loaded: false,
                notificationHub: connection,
            }
        },
        methods: {
            async load() {
                this.startSignalR();
            },
            async startSignalR() {
                try {
                    if (this.notificationHub.state === "Disconnected")
                        await this.notificationHub.start();
                } catch (err) {
                    setTimeout(() => this.startSignalR(), 10000);
                }
            },
            onNotificationHubConnected() {
                this.notificationHub.invoke("joinChat", this.uid);
            },
            onNotificationHubDisconnected() {

            },
        },
        created() {
            this.load();
        },
        beforeDestroy() {
            if (this.notificationHub.state === "Connected")
                this.notificationHub.invoke("leaveChat", this.uid);
        },
        watch: {
            "notificationHub.state": {
                handler() {
                    if (this.notificationHub.state === "Connected") {
                        this.onNotificationHubConnected();
                    }
                    if (this.notificationHub.state === "Disconnected") {
                        this.onNotificationHubDisconnected();
                    }
                },
                immediate: true
            }
        }
    }

</script>
