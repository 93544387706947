<template>
    <v-app :class="['branding--' + businessCode.toLowerCase(), 'branding--' + mediaName.toLowerCase()]">
        <component :is="mediaHeader"
                   :account="account"
                   :media-setup="mediaSetup"
                   :logout-return-to="currentLocation"
                   :logo-redirects-to-self="false"></component>
        <main>
            <v-fade-transition>
                <router-view :account="account"
                             :business-code="businessCode"
                             :media-name="mediaName"
                             :media-setup="mediaSetup"></router-view>
            </v-fade-transition>
        </main>
        <component :is="mediaFooter"></component>
    </v-app>
</template>


<script>
    import jpMediaHeader from "../../branding/jp/jyllands-posten/components/VMediaHeader";
    import jpMediaFooter from "../../branding/jp/jyllands-posten/components/VMediaFooter";
    import jpMediaSetup from "../../branding/jp/jyllands-posten/setup";
    
    import finansMediaHeader from "../../branding/jp/finans/components/VMediaHeader";
    import finansMediaFooter from "../../branding/jp/finans/components/VMediaFooter";
    import finansMediaSetup from "../../branding/jp/finans/setup";

    import polMediaHeader from "../../branding/politiken/components/VMediaHeader";
    import polMediaFooter from "../../branding/politiken/components/VMediaFooter";
    import polMediaSetup from "../../branding/politiken/setup";

    export default {
        components: {
            'jyllands-posten-header': jpMediaHeader,
            'jyllands-posten-footer': jpMediaFooter,
            'finans-header': finansMediaHeader,
            'finans-footer': finansMediaFooter,
            'politiken-header': polMediaHeader,
            'politiken-footer': polMediaFooter
        },
        props: {
            account: Object,
            businessCode: String,
            mediaName: String
        },
        computed: {
            mediaHeader() {
                return this.mediaName === null ? null : this.mediaName + '-header';
            },
            mediaFooter() {
                return this.mediaName === null ? null : this.mediaName + '-footer';
            },
            mediaSetup() {
                switch (this.mediaName) {
                    case "jyllands-posten":
                        return jpMediaSetup;
                    case "finans":
                        return finansMediaSetup;
                    case "politiken":
                        return polMediaSetup;
                }
                return null;
            },
            currentLocation() { return window.location }
        },
        methods: {
            load() {
                this.setFavicon();
                this.setTheme();
            },
            setFavicon() {
                const favicon = document.getElementById("favicon");
                favicon.href = this.mediaSetup?.faviconUrl;
            },
            setTheme() {
                if (!this.mediaSetup?.vuetify.theme)
                    return;

                Object.keys(this.mediaSetup.vuetify.theme).forEach(i => {
                    this.$vuetify.theme.themes.light[i] = this.mediaSetup.vuetify.theme[i];
                });
            }
        },
        created() {
            this.load();
        }
    }
</script>