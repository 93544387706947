export default {
    faviconUrl: "https://politiken.dk/static/assets/favicon/politiken/favicon.ico",
    createLoginUrl: "https://medielogin.dk/Politiken/Create",
    loginUrl: "/account/login?returnto=%2fpol",
    debateAuthorFieldLabels: {
        name: "*Navn (offentliggøres)",
        profession: "*Profession/beskæftigelse (offentliggøres – dog ikke på typen Læserbrev)",
        zipCode: "*Postnummer (offentliggøres ikke)",
        city: "*By (offentliggøres kun på typen Læserbrev)",
        address: "*Adresse (offentliggøres ikke)",
        gender: "*Køn (offentliggøres ikke)",
        birthDate: "*Fødselsår (4 cifre) (offentliggøres ikke)"
    },
    vuetify: {
        inputProps: {
            solo: true,
            hideDetails: "auto",
            "data-lpignore": "true"
        },
        textAreaProps: {
            solo: true,
            hideDetails: "auto",
            autoGrow: true,
            rows: 1
        },
        buttonProps: {
            depressed: true,
            small: true,
            class: "v-btn--pol"
        },
        dialogProps: {
            width: "700px",
            overlayColor: "#fff",
            overlayOpacity: 0.7,
            rounded: false
        },
        theme: {
            primary: "#c00",
            error: "#ce2d4c"
        }
    }
}