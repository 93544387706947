<template>
    <v-text-field :value="displayValue"
                  v-bind="$attrs"
                  :label="label"
                  type="number"
                  @change="emitValue($event)"
                  @keydown="keyFilter"
                  ref="component"></v-text-field>
</template>
<script>
    export default {
        computed: {
            displayValue() {
                return this.correctValue(this.value);
            }
        },
        props: {
            min: {
                required: false,
                type: Number,
                default: -0x80000000
            },
            max: {
                required: false,
                type: Number,
                default: 0x80000000
            },
            value: {
                required: true,
            },
            label: String
        },
        methods: {
            correctValue(value) {
                const number = parseInt(value);
                let newValue;
                if (isNaN(number))
                    newValue = null;
                else if (number > this.max)
                    newValue = this.max;
                else if (number < this.min)
                    newValue = this.min;
                else
                    newValue = number;
                return newValue;
            },
            emitValue(value) {
                //Emit null first then real value to force text input rebind
                this.$emit("input", null);
                this.$nextTick(function () {
                    this.$emit("input", this.correctValue(value));
                });
            },
            keyFilter(e) {
                // Allow: backspace, delete, tab, escape, enter, minus, numpad minus
                if ([8, 46, 9, 27, 13, 173, 189, 109].indexOf(e.keyCode) !== -1 ||
                    // Allow: Ctrl+A
                    (e.keyCode === 65 && e.ctrlKey === true) ||
                    // Allow: Ctrl+C
                    (e.keyCode === 67 && e.ctrlKey === true) ||
                    // Allow: Ctrl+V
                    (e.keyCode === 86 && e.ctrlKey === true) ||
                    // Allow: Ctrl+X
                    (e.keyCode === 88 && e.ctrlKey === true) ||
                    // Allow: F1-F12
                    (e.keyCode >= 112 && e.keyCode <= 123) ||
                    // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    return;
                }
                // Prevent if not a number
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            }
        },
        mounted() {
            const nativeInput = this.$refs.component.$el.querySelector("input");
            if (nativeInput) {
                nativeInput.min = this.min;
                nativeInput.max = this.max;
            }
        }
    }
</script>
<style>
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
</style>