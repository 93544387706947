import api from "../api"
import { ArticleSource } from "../enums"
import { humanReadableFileSize } from "vuetify/lib/util/helpers"
import exifr from "exifr"

const validationMessageFieldRequired = "Dette felt skal udfyldes, før du kan indsende dit indlæg";
const validationMessageCommon = "Du har fejludfyldt et eller flere felter. Korriger fejlen og prøv at sende igen.";

export const imageFieldsRequirements = {
    caption: { required: false, max: 2000 },
    photographer: { required: false, max: 32 },
    copyright: { required: false, max: 32 }
};

export const mapAndJoinFiles = async (files, chosenFiles, maxFiles) => {
    const filesLeft = maxFiles - files.length;
    if (filesLeft <= 0)
        return;

    const exifrOptions = {
        ihdr: true,
        iptc: true,
        exif: false,
        gps: false,
        tiff: false,
        ifd0: false,
        mergeOutput: false
    };

    const guessEncoding = (str) => {
        //Problem: exifr incorrectly reads utf-8 string as latin1 (see https://github.com/MikeKovarik/exifr/issues/82)
        //This is defined by IPTC attribute CodedCharacterSet which is ignored
        //Example: børnehave -> bÃ¸rnehave.
        //Here we are trying to guess if this a case.
        //1. input => array of chars => array of charcodes
        //2. if input contains chars with code > 255 - means it's already utf-8, return source
        //3. decode charcode array using utf-8 (by default) decoder
        //4. check for "replacement character" - � (65533) 
        //   - if it present then source was in Latin1 already, return source
        //   - if not - return sucessfully decoded string.
        if (!str)
            return null;
        
        const charCodeArray = [...str].map(c => c.charCodeAt(0));
        if (charCodeArray.some(charCode => charCode > 255))
            return str;

        const decoder = new TextDecoder();
        const array = new Uint8Array(charCodeArray)
        const result = decoder.decode(array);
        return result.indexOf("�") >= 0 ? str : result;
    }

    const mapFn = async file => {
        let caption = null;
        let photographer = null;
        let copyright = null;

        try {
            const meta = await exifr.parse(file, exifrOptions);
            caption = guessEncoding(meta.iptc.Caption);
            photographer = guessEncoding(meta.iptc.Byline);
            copyright = guessEncoding(meta.iptc.Credit);
        }
        catch (e) {
            console.log("Error parsing IPTC metadata: " + e.message)
        }

        return {
            file: file,
            name: file.name,
            size: file.size,
            caption: caption,
            photographer: photographer,
            copyright: copyright
        }
    };
    const mappedChosenFiles = await Promise.all(chosenFiles.slice(0, filesLeft).map(mapFn))
    return [...files, ...mappedChosenFiles];
}

export const isImageFile = (fileName, imageFileExtensions) => {
    //https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
    const extension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
    if (!extension)
        return false;

    return imageFileExtensions.includes(extension.toLowerCase());
};

export const getTextValidationRules = (requirements) => {
    const rules = [];
    if (requirements.required)
        rules.push(v => !!v || validationMessageFieldRequired);
    if (requirements.min)
        rules.push(v => v == null || v.length >= requirements.min || false);
    if (requirements.max)
        rules.push(v => v == null || v.length <= requirements.max || false);

    return rules;
};

export default {
    props: {
        account: {
            type: Object,
            required: true
        },
        businessCode: {
            type: String,
            required: true
        },
        articleSource: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loaded: false,

            contentTypes: null,
            genders: null,
            businessSetup: null,

            currentContentType: null,
            article: {
                contentTypeName: null,
                sender: {
                    name: this.account.name,
                    email: this.account.email,
                    address: this.account.address,
                    birthDate: this.account.birthDate,
                    gender: this.account.gender,
                    education: null,
                    profession: null,
                    phone: this.account.phone
                },
                authors: [this.createDefaultAuthor()],

                title: null,
                text: null,
                subtitle: null,
                prefix: null,
                frontPageTitle: null,
                summary: null,
                comment: null,

                facts: [],
                reviews: [],

                underage: null,
                exclusiveRight: false
            },

            senderFieldsRequirements: {
                phone: { required: true, min: 1, max: 100 }
            },
            factsAndReviewsFieldsRequirements: {
                text: { required: true, min: 1, max: 4000 }
            },
            imageFieldsRequirements: imageFieldsRequirements,

            files: [],
            maxFileSize: api.maxFileSize,
            maxFiles: api.maxFiles,

            valid: true,
            pendingSave: false,
            saveError: null,

            validationMessageFieldRequired: validationMessageFieldRequired,
            validationMessageCommon: validationMessageCommon,
        }
    },
    computed: {
        isFreelance() { return this.articleSource === ArticleSource.Freelance },
        authorFieldsRequirements() {
            return this.isFreelance
                ? {
                    name: { required: true, min: 1, max: 250 },
                    email: { required: false, min: 0, max: 250 },
                    address: { required: false, min: 0, max: 250 },
                    zipCode: { required: false, min: 0, max: 20 },
                    city: { required: false, min: 0, max: 100 },
                    profession: { required: false, min: 0, max: 500 },
                    gender: { required: false },
                    birthDate: { required: false }
                }
                : {
                    name: { required: true, min: 1, max: 250 },
                    email: { required: false, min: 1, max: 250 },
                    address: { required: true, min: 1, max: 250 },
                    zipCode: { required: true, min: 1, max: 20 },
                    city: { required: true, min: 1, max: 100 },
                    profession: { required: true, min: 1, max: 500 },
                    gender: { required: true },
                    birthDate: { required: true }
                };
        },
        fieldsVisibility() {
            return {
                enableExclusiveRight: this.currentContentType ?
                    this.currentContentType.enableExclusiveRight :
                    this.contentTypes.every(ct => ct.enableExclusiveRight),
                enableUnderage: this.currentContentType ?
                    this.currentContentType.enableUnderage :
                    this.contentTypes.every(ct => ct.enableUnderage),

                enableFileUpload: this.currentContentType ?
                    this.currentContentType.enableFileUpload :
                    this.contentTypes.every(ct => ct.enableFileUpload),
                enableImageCaption: this.currentContentType ?
                    this.currentContentType.enableImageCaption :
                    this.contentTypes.every(ct => ct.enableImageCaption),
                enableImagePhotographer: this.currentContentType ?
                    this.currentContentType.enableImagePhotographer :
                    this.contentTypes.every(ct => ct.enableImagePhotographer),
                enableImageCopyright: this.currentContentType ?
                    this.currentContentType.enableImageCopyright :
                    this.contentTypes.every(ct => ct.enableImageCopyright),

                enableAuthorGender: this.currentContentType ?
                    this.currentContentType.enableAuthorGender :
                    this.contentTypes.every(ct => ct.enableAuthorGender),
                enableAuthorAddress: this.currentContentType ?
                    this.currentContentType.enableAuthorAddress :
                    this.contentTypes.every(ct => ct.enableAuthorAddress),
                enableAuthorZipCode: this.currentContentType ?
                    this.currentContentType.enableAuthorZipCode :
                    this.contentTypes.every(ct => ct.enableAuthorZipCode),
                enableAuthorCity: this.currentContentType ?
                    this.currentContentType.enableAuthorCity :
                    this.contentTypes.every(ct => ct.enableAuthorCity),
                enableAuthorProfession: this.currentContentType ?
                    this.currentContentType.enableAuthorProfession :
                    this.contentTypes.every(ct => ct.enableAuthorProfession),
                enableAuthorBirthDate: this.currentContentType ?
                    this.currentContentType.enableAuthorBirthDate :
                    this.contentTypes.every(ct => ct.enableAuthorBirthDate),
                enableFacts: this.currentContentType ?
                    this.currentContentType.enableFacts :
                    this.contentTypes.every(ct => ct.enableFacts),
                enableReviews: this.currentContentType ?
                    this.currentContentType.enableReviews :
                    this.contentTypes.every(ct => ct.enableReviews)
            }
        },
        articleFieldsRequirements() {
            return {
                title: this.getTextFieldRequirements("title", true),
                subtitle: this.getTextFieldRequirements("subtitle"),
                prefix: this.getTextFieldRequirements("prefix"),
                frontPageTitle: this.getTextFieldRequirements("frontPageTitle"),
                summary: this.getTextFieldRequirements("summary"),
                text: this.getTextFieldRequirements("text", true),
                comment: this.getTextFieldRequirements("comment")
            }
        },
        plainArticleText() { return this.getPlainTextFromHtml(this.article.text); },
        reviewRatingSymbols() {
            if (!this.businessSetup || !this.businessSetup.enableRatingSymbol)
                return [];
            const symbolValues = this.businessSetup.ratingSymbolValues.split(";");
            const symbolLabels = this.businessSetup.ratingSymbolLabels.split(";");
            return symbolValues.map((item, index) => { return { id: item, name: symbolLabels[index] } });
        },
        reviewRatingValues() {
            if (!this.businessSetup)
                return [];
            const ratingValues = this.businessSetup.ratingValues.split(";");
            const ratingLabels = this.businessSetup.ratingLabels.split(";");
            return ratingValues.map((item, index) => { return { id: item, name: ratingLabels[index] } });
        },
        imageFileExtensions() {
            if (!this.businessSetup)
                return [];
            return this.businessSetup.imageFileExtensions.split(";");
        }
    },
    methods: {
        getContentTypeDisplayName(contentType) {
            const lcn = (n) => n.toLocaleString("da");

            let textLengthPart = null;
            if (contentType.textMinLength && contentType.textMaxLength)
                textLengthPart = `${lcn(contentType.textMinLength)} - ${lcn(contentType.textMaxLength)} anslag`;
            else if (contentType.textMinLength)
                textLengthPart = `${lcn(contentType.textMinLength)} anslag`;
            else if (contentType.textMaxLength)
                textLengthPart = `${lcn(contentType.textMaxLength)} anslag`;

            const displayName = textLengthPart ? `${contentType.name} (${textLengthPart})` : contentType.name;
            return displayName;
        },
        createAuthor() {
            return {
                name: null,
                email: null,
                address: null,
                zipCode: null,
                city: null,
                profession: null,
                gender: null,
                birthDate: null
            };
        },
        createDefaultAuthor() {
            const author = this.createAuthor();
            const acc = this.account;

            author.name = acc.name;
            author.zipCode = acc.zipCode;
            author.city = acc.city;
            author.address = [acc.streetName, acc.houseNumber].filter(Boolean).join(" ");
            author.birthDate = acc.birthDate;
            if (acc.gender) {
                if (acc.gender.toLowerCase() === "male")
                    author.gender = "Mand";
                else if (acc.gender.toLowerCase() === "female")
                    author.gender = "Kvinde";
            }
            return author;
        },
        addAuthor() {
            if (this.article.authors.length >= this.businessSetup.maxAuthors)
                return;
            this.article.authors.push(this.createAuthor());
        },
        removeAuthor(index) {
            if (index === 0)
                return;
            this.article.authors.splice(index, 1);
        },
        getAuthorBirthYear(author) {
            if (!author || author.birthDate === null)
                return null;
            return author.birthDate.substr(0, 4);
        },
        setAuthorBirthYear(author, year) {
            if (!author)
                return;

            author.birthDate = `${year}-01-01`;
        },
        addFact() {
            if (this.article.facts.length >= this.businessSetup.maxFacts)
                return;
            this.article.facts.push({
                text: null
            });
        },
        addReview() {
            if (this.article.reviews.length >= this.businessSetup.maxReviews)
                return;
            this.article.reviews.push({
                text: null,
                ratingSymbol: this.businessSetup?.ratingSymbolDefaultValue,
                rating: this.businessSetup?.ratingDefaultValue
            });
        },
        async addFiles(files) {
            this.files = await mapAndJoinFiles(this.files, files, this.maxFiles);
        },
        formatFileSize(file) {
            return `${humanReadableFileSize(file.size)}`;
        },
        isImageFile(file) {
            return isImageFile(file.name, this.imageFileExtensions);
        },
        removeItemFromArray(array, index) {
            array.splice(index, 1);
        },
        getDisabledLinkClass() {
            return this.pendingSave ? "text--disabled v-btn--disabled" : null;
        },
        getTextFieldRequirements(propertyName, alwaysRequired) {
            if (this.currentContentType !== null)
                return {
                    required: alwaysRequired || this.currentContentType[propertyName + "MinLength"] !== null,
                    min: this.currentContentType[propertyName + "MinLength"],
                    max: this.currentContentType[propertyName + "MaxLength"],
                    visible: alwaysRequired ||
                        this.currentContentType[propertyName + "MinLength"] !== null ||
                        this.currentContentType[propertyName + "MaxLength"] !== null
                };
            return {
                required: alwaysRequired || this.contentTypes.every(ct => ct[propertyName + "MinLength"] !== null),
                min: null,
                max: null,
                visible: alwaysRequired ||
                    this.contentTypes.every(ct =>
                        ct[propertyName + "MinLength"] !== null || ct[propertyName + "MaxLength"] !== null)
            };
        },
        preprocessArticle() {
            this.article.contentTypeName = this.currentContentType.name;
            this.article.authors.forEach(author => {
                if (!this.fieldsVisibility.enableAuthorAddress)
                    author.address = null;
                if (!this.fieldsVisibility.enableAuthorCity)
                    author.city = null;
                if (!this.fieldsVisibility.enableAuthorGender)
                    author.gender = null;
                if (!this.fieldsVisibility.enableAuthorProfession)
                    author.profession = null;
                if (!this.fieldsVisibility.enableAuthorZipCode)
                    author.zipCode = null;
                if (!this.fieldsVisibility.enableAuthorBirthDate)
                    author.birthDate = null;
            });
            if (!this.fieldsVisibility.enableFacts)
                this.article.facts = null;
            if (!this.fieldsVisibility.enableReviews)
                this.article.reviews = null;
        },
        preprocessFiles() {
            if (!this.fieldsVisibility.enableFileUpload) {
                this.files = [];
                return;
            }
            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                const isImage = this.isImageFile(file);
                if (!isImage || !this.fieldsVisibility.enableImageCaption)
                    file.caption = null;
                if (!isImage || !this.fieldsVisibility.enableImagePhotographer)
                    file.photographer = null;
                if (!isImage || !this.fieldsVisibility.enableImageCopyright)
                    file.copyright = null;
            }
        },
        async submit() {
            if (!this.$refs.form.validate()) {
                this.$emit("validation-failed");
                return;
            }

            if (this.pendingSave)
                return;

            try {
                this.pendingSave = true;
                this.saveError = null;

                this.preprocessArticle();
                this.preprocessFiles();
                const uid = await api.createArticle(this.article, this.businessCode, this.mediaName);

                for (let i = 0; i < this.files.length; i++) {
                    try {
                        await api.uploadArticleFile(uid, this.files[i]);
                    }
                    catch (e) {
                        console.log(`Error uploading file: ${e.message}`);
                    }
                }

                this.$emit("article-created", uid);
            }
            catch (e) {
                this.saveError = e.message;
            }
            finally {
                this.pendingSave = false;
            }
        },
        getRequiredValidationRule(validationMessage, nullCheckOnly) {
            return [v => (nullCheckOnly ? v !== null : !!v) || (validationMessage ?? this.validationMessageFieldRequired)];
        },
        getTextValidationRules(requirements) {
            return getTextValidationRules(requirements);
        },
        getArticleTextValidationRules(requirements, plainArticleText) {
            const rules = [];
            if (requirements.required)
                rules.push(v => !!v || this.validationMessageFieldRequired);
            if (requirements.min)
                rules.push(v => v == null || plainArticleText.length >= requirements.min || false);
            if (requirements.max)
                rules.push(v => v == null || plainArticleText.length <= requirements.max || false);

            return rules;
        },
        getHtmlTextValidationRules(requirements) {
            const rule = v => {
                if (requirements.required && !v)
                    return this.validationMessageFieldRequired;

                if (!v)
                    return true;

                const plainText = this.getPlainTextFromHtml(v);
                if ((requirements.min && plainText.length < requirements.min) ||
                    (requirements.max && plainText.length > requirements.max))
                    return false;

                return true;
            };
            return [rule];
        },
        getPlainTextFromHtml(html) {
            if (html)
                //replace tags with spaces, then join consecutive spaces in one, then trim
                return html.replace(/<[^>]*>/g, " ").replace(/(?:\s|&nbsp;)+/gi, " ").trim();
            return null;
        },
        getCounterValue(value, requirements, alwaysVisible) {
            if (alwaysVisible || value !== null && requirements.max !== null && value.length > requirements.max)
                return requirements.max;
            return null;
        },
        getCounterText(props) {
            return `(${props.value}/${props.max} anslag)`;
        }
    }
}