<template>
    <bubble-menu v-if="editor"
                 :editor="editor"
                 :tippy-options="{ duration: 100, zIndex: 199 }"
                 class="bubble-menu">
        <div>
            <v-toolbar rounded
                       float>
                <v-btn-toggle dense
                              multiple>
                    <v-btn @click.stop="editor.chain().focus().toggleBold().run()"
                           :input-value="editor.isActive('bold')">
                        <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                    <v-btn @click.stop="editor.chain().focus().toggleItalic().run()"
                           :input-value="editor.isActive('italic')">
                        <v-icon>mdi-format-italic</v-icon>
                    </v-btn>
                </v-btn-toggle>

                <v-btn-toggle dense
                              class="ml-1">
                    <v-btn @click.stop="editor.chain().focus().toggleBulletList().run()"
                           :input-value="editor.isActive('bulletList')">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn @click.stop="editor.chain().focus().toggleOrderedList().run()"
                           :input-value="editor.isActive('orderedList')">
                        <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-btn-toggle dense
                              class="ml-1">
                    <v-btn @click.stop="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                           :input-value="editor.isActive('heading', { level: 3 })">
                        <v-icon>mdi-format-header-3</v-icon>
                    </v-btn>
                </v-btn-toggle>

                <v-btn-toggle v-if="linkDialogEnabled"
                              dense
                              class="ml-1">
                    <v-btn @click="showLinkDialog()"
                           :input-value="editor.isActive('link')">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-toolbar>

            <v-dialog v-if="linkDialogEnabled && linkDialog"
                      v-model="linkDialog"
                      max-width="700px"
                      persistent>
                <slot name="linkDialogContent">
                    <v-card>
                        <v-card-title></v-card-title>
                        <v-card-text>
                            <v-text-field v-model="linkUrl"
                                          hide-details
                                          autofocus
                                          placeholder="URL"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn v-if="editor.isActive('link')"
                                   @click="editor.commands.unsetLink(); hideLinkDialog()"
                                   v-bind="linkDialogButtonProps">
                                <span v-text="$t('remove')"></span>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :color="linkDialogButtonProps ? null : 'blue darken-1'"
                                   text
                                   @click="hideLinkDialog()"
                                   v-bind="linkDialogButtonProps">
                                <span v-text="$t('cancel')"></span>
                            </v-btn>
                            <v-btn :color="linkDialogButtonProps ? null : 'primary'"
                                   @click="editor.commands.setLink({ href: linkUrl }); hideLinkDialog()"
                                   v-bind="linkDialogButtonProps">
                                <span v-text="$t('save')"></span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </slot>
            </v-dialog>

        </div>
    </bubble-menu>
</template>

<script>
    import { BubbleMenu } from "@tiptap/vue-2"

    export default {
        components: { BubbleMenu },
        props: {
            editor: {
                required: true
            },
            linkDialogEnabled: {
                type: Boolean,
                default: false
            },
            linkDialogButtonProps: Object
        },
        data() {
            return {
                linkDialog: false,
                linkUrl: null
            }
        },
        methods: {
            showLinkDialog() {
                this.linkUrl = this.editor.getAttributes('link').href;
                this.linkDialog = true;
            },
            hideLinkDialog() {
                this.linkUrl = null
                this.linkDialog = false;
            }
        },
    }
</script>
<style>
    .bubble-menu .v-toolbar .v-btn-toggle--dense > .v-btn.v-btn {
        min-width: 40px;
    }
</style>