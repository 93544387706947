export const ArticleSource = {
    Debate: "Debate",
    Freelance: "Freelance"
};
Object.freeze(ArticleSource);


export const RouteName = {
    PublicationRoot: "Root",
    Welcome: "Welcome",
    NoAccess: "NoAccess",
    NewArticle: "NewArticle",
    ArticleSent: "ArticleSent",
    ArticleChat: "ArticleChat",
    ArticleNotFound: "ArticleNotFound"
};
Object.freeze(RouteName);


export const FreelanceAccess = {
    Requested: "Requested",
    Allowed: "Allowed",
    Denied: "Denied",
    Initial: "Initial"
};
Object.freeze(FreelanceAccess);