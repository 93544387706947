import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import differenceInYears from "date-fns/differenceInYears";
import startOfToday from "date-fns/startOfToday";
import da from "date-fns/locale/da/index.js";
import i18n from "@/i18n/index.js";

const dayValues = {
    narrow: ["S", "M", "T", "O", "T", "F", "L"],
    short: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    abbreviated: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
    wide: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
};

const daModified = {
    ...da,
    localize: {
        ...da.localize,
        //Number in the calendar without a dot
        ordinalNumber: (dirtyNumber) => {
            return Number(dirtyNumber);
        },
        day: (dayOfWeekNumber, options) => {
            return dayValues[options.width][dayOfWeekNumber];
        }
    }
};

export default {
    methods: {
        formatAsDate: value => formatValue(value, "eeeeee, dd-MM-yyyy"),
        formatAsDateTime: value => formatValue(value, "eeeeee, dd-MM-yyyy HH:mm"),
        formatAsDateTimeSec: value => formatValue(value, "eeeeee, dd-MM-yyyy HH:mm:ss"),
        
        formatAsShortDate: value => moreThanYearAgo(value) ? formatValue(value, "eeeeee, dd-MM-yyyy") : formatValue(value, "eeeeee, dd-MM"),
        formatAsShortDateTime: value => moreThanYearAgo(value) ? formatValue(value, "eeeeee, dd-MM-yyyy – HH:mm") : formatValue(value, "eeeeee, dd-MM – HH:mm"),
        formatAsShortDateTimeSec: value => moreThanYearAgo(value) ? formatValue(value, "eeeeee, dd-MM-yyyy – HH:mm:ss") : formatValue(value, "eeeeee, dd-MM – HH:mm:ss"),
        
        formatWithCustomPattern: (value, pattern) => formatValue(value, pattern)
    }
}

const formatValue = (value, pattern) => format(parseISO(value), pattern, { locale: i18n.locale === "da" ? daModified : null });
const moreThanYearAgo = (value) => value ? differenceInYears(startOfToday(), parseISO(value)) > 0 : false;