const baseUrl = "/ext-api/v1.0";

const maxFileSize = 50000000;
const maxFiles = 20;

const executeGetRequest = async url => {
    const response = await fetch(url);
    if (!response.ok)
        await throwError(response);

    return response.json();
}

const throwError = async response => {
    const errorObject = await response.json();
    throw new Error(errorObject.title);
}

export default {
    /*------ Account ------*/
    async getAccount() {
        try {
            const accountResponse = await fetch("/account");
            return await accountResponse.json();
        }
        catch (e) {
            return Promise.resolve(null);
        }
    },
    autoLogin() {
        const url = new URL(window.location);
        url.searchParams.append("returnTo", url.pathname);
        url.pathname = "/account/login";
        window.location = url.href;
    },


    /*------ Article ------*/
    async getArticleInfo(uid) {
        const url = `${baseUrl}/articles/${uid}`;

        const info = {
            found: false,
            businessCode: null,
            mediaName: null
        }

        const response = await fetch(url, { method: "HEAD" });
        if (response.ok) {
            info.found = true;
            info.businessCode = response.headers.get("debate-business-code").toLowerCase();
            info.mediaName = response.headers.get("debate-media-name").toLowerCase();
        }

        return Promise.resolve(info);
    },
    async createArticle(article, businessCode, mediaName) {
        const url = `${baseUrl}/articles/${businessCode}/${mediaName}`;
        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(article)
        });

        if (!response.ok)
            await throwError(response);

        const uid = (await response.json()).uid;
        return Promise.resolve(uid);
    },
    async getArticle(uid) {
        const url = `${baseUrl}/articles/${uid}`;
        return executeGetRequest(url);
    },
    async getArticleContentType(uid) {
        const url = `${baseUrl}/articles/${uid}/contenttype`;
        return executeGetRequest(url);
    },


    /*------ Article chat ------*/
    async getChat(uid) {
        const url = `${baseUrl}/articles/${uid}/chat`;
        return executeGetRequest(url);
    },
    async getChatMessages(uid) {
        const url = `${baseUrl}/articles/${uid}/chat/messages`;
        return executeGetRequest(url);
    },
    async createChatMessage(uid, messageText) {
        const url = `${baseUrl}/articles/${uid}/chat/messages`;

        const formData = new FormData();
        formData.append("text", messageText);

        const response = await fetch(url, {
            method: "POST",
            body: formData
        });

        if (!response.ok)
            await throwError(response);

        const chatMessage = await response.json();
        return Promise.resolve(chatMessage);
    },


    /*------ Article files ------*/

    maxFileSize: maxFileSize,
    maxFiles: maxFiles,

    async getArticleFiles(uid) {
        const url = `${baseUrl}/articles/${uid}/files`;
        return executeGetRequest(url);
    },
    getArticleFileDownloadUrl(uid, fileId) {
        return `${baseUrl}/articles/${uid}/files/${fileId}`;
    },
    async uploadArticleFile(uid, file) {
        const url = `${baseUrl}/articles/${uid}/files`;

        if (file.size > maxFileSize)
            return Promise.resolve();

        const formData = new FormData();
        formData.append("file", file.file);
        if (file.caption)
            formData.append("caption", file.caption);
        if (file.photographer)
            formData.append("photographer", file.photographer);
        if (file.copyright)
            formData.append("copyright", file.copyright);

        const response = await fetch(url, {
            method: "POST",
            body: formData
        });

        if (!response.ok)
            await throwError(response);

        return Promise.resolve();
    },
    async deleteArticleFile(uid, fileId) {
        const url = `${baseUrl}/articles/${uid}/files/${fileId}`;
        const response = await fetch(url, { method: "DELETE" });

        if (!response.ok)
            await throwError(response);

        return Promise.resolve();
    },


    /*------ Dictionaries ------*/
    async getBusiness(businessCode) {
        const url = `${baseUrl}/businesses/${businessCode}`;
        return executeGetRequest(url);
    },
    async getBusinessSetup(businessCode) {
        const url = `${baseUrl}/businesses/${businessCode}/setup`;
        return executeGetRequest(url);
    },
    async getMedia(businessCode, mediaName) {
        const url = `${baseUrl}/businesses/${businessCode}/${mediaName}`;
        return executeGetRequest(url);
    },
    async getGenders() {
        const url = `${baseUrl}/genders`;
        return executeGetRequest(url);
    },


    /*------ Sender ------*/
    async getCurrentSenderPermissions(businessCode) {
        const url = `${baseUrl}/senders/${businessCode}/current/permissions`;
        return executeGetRequest(url);
    },
    async requestFreelanceAccess(businessCode, sender) {
        const url = `${baseUrl}/senders/${businessCode}/current/requestfreelanceaccess`;

        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(sender)
        });

        if (!response.ok)
            await throwError(response);

        return Promise.resolve();
    }
}
