<template>
    <v-container>
        <div class="form-section">
            <component :is="mediaName + 'StaticWelcomeHeader'"></component>

            <div class="form-section-actions">
                <v-btn :href="mediaSetup.createLoginUrl"
                       v-bind="mediaSetup.vuetify.buttonProps"
                       class="mr-3">Opret Medielogin</v-btn>
                <v-btn :href="mediaSetup.loginUrl"
                       v-bind="mediaSetup.vuetify.buttonProps">Log ind</v-btn>
            </div>
        </div>

        <v-divider class="form-section-divider"></v-divider>

        <div class="form-section">
            <component :is="mediaName + 'StaticWelcomeFooter'"></component>
        </div>
    </v-container>
</template>

<script>
    import jyllandsPostenStaticWelcomeHeader from "../../branding/jp/jyllands-posten/components/VStaticWelcomeHeader"
    import jyllandsPostenStaticWelcomeFooter from "../../branding/jp/jyllands-posten/components/VStaticWelcomeFooter"

    import finansStaticWelcomeHeader from "../../branding/jp/finans/components/VStaticWelcomeHeader"
    import finansStaticWelcomeFooter from "../../branding/jp/finans/components/VStaticWelcomeFooter"
    
    import politikenStaticWelcomeHeader from "../../branding/politiken/components/VStaticWelcomeHeader"
    import politikenStaticWelcomeFooter from "../../branding/politiken/components/VStaticWelcomeFooter"
    
    export default {
        components: {
            jyllandsPostenStaticWelcomeHeader,
            jyllandsPostenStaticWelcomeFooter,
            finansStaticWelcomeHeader,
            finansStaticWelcomeFooter,
            politikenStaticWelcomeHeader,
            politikenStaticWelcomeFooter
        },
        props: {
            account: Object,
            businessCode: String,
            mediaName: String,
            mediaSetup: Object
        }
    }
</script>