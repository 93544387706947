<script>
    import { VInput, VLabel, VCounter } from "vuetify/lib"
    import { Editor, EditorContent } from "@tiptap/vue-2"

    import Document from "@tiptap/extension-document"
    import Paragraph from "@tiptap/extension-paragraph"
    import Text from "@tiptap/extension-text"
    import Bold from "@tiptap/extension-bold"
    import Italic from "@tiptap/extension-italic"
    import Heading from "@tiptap/extension-heading"
    import Blockquote from "@tiptap/extension-blockquote"
    import BulletList from "@tiptap/extension-bullet-list"
    import OrderedList from "@tiptap/extension-ordered-list"
    import ListItem from "@tiptap/extension-list-item"
    import Link from "@tiptap/extension-link"
    import History from "@tiptap/extension-history"

    import VHtmlInputBubbleMenu from "@/components/VHtmlInputBubbleMenu"

    export default {
        name: "VHtmlInput",
        extends: VInput,
        data() {
            return {
                editor: new Editor({
                    extensions: [
                        Document,
                        Paragraph,
                        Text,

                        Bold,
                        Italic,

                        Heading.configure({
                            levels: [3]
                        }),
                        Blockquote,

                        BulletList,
                        OrderedList,
                        ListItem,

                        Link.configure({
                            openOnClick: false
                        }),
                        History
                    ],
                    content: this.value,
                    onUpdate: this.onUpdate,
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    editable: this.isInteractive
                }),
                isBooted: false
            };
        },
        components: { EditorContent, VHtmlInputBubbleMenu },
        computed: {
            classes() {
                return {
                    ...VInput.options.computed.classes.call(this),
                    "v-text-field": true,
                    "v-text-field--full-width": this.fullWidth,
                    "v-text-field--prefix": this.prefix,
                    "v-text-field--single-line": this.isSingle,
                    "v-text-field--solo": this.isSolo,
                    "v-text-field--solo-inverted": this.soloInverted,
                    "v-text-field--solo-flat": this.flat,
                    "v-text-field--filled": this.filled,
                    "v-text-field--is-booted": this.isBooted,
                    "v-text-field--enclosed": this.isEnclosed,
                    "v-text-field--reverse": this.reverse,
                    "v-text-field--outlined": this.outlined,
                    "v-text-field--placeholder": this.placeholder,
                    "v-text-field--rounded": this.rounded,
                    "v-text-field--shaped": this.shaped
                }
            },
            showLabel() {
                return this.hasLabel && !(this.isSingle && this.labelValue)
            },
            labelValue() {
                return this.isFocused || this.isLabelActive
            },
            computedColor() {
                if (this.isDisabled) return undefined
                if (this.color) return this.color
                // It's assumed that if the input is on a
                // dark background, the user will want to
                // have a white color. If the entire app
                // is setup to be dark, then they will
                // like want to use their primary color
                if (this.isDark && !this.appIsDark) return "white"
                else return "primary"
            },
            validationState() {
                if (this.isDisabled) return undefined
                if (this.hasError && this.shouldValidate) return "error"
                if (this.hasSuccess) return "success"
                if (this.hasColor) return this.computedColor
                return undefined
            },
            isSolo() {
                return this.solo || this.soloInverted
            },
            isEnclosed() {
                return (
                    this.filled ||
                    this.isSolo ||
                    this.outlined
                )
            },
            computedCounterValue() {
                //if (typeof this.counterValue === 'function') {
                //    return this.counterValue(this.internalValue);
                //}

                //return [...(this.internalValue || '').toString()].length;
                if (this.counterValue)
                    return this.counterValue;
                return 0;
            },

            hasCounter() {
                return this.counter !== false && this.counter != null;
            },
            hasDetails() {
                return VInput.options.computed.hasDetails.call(this) || this.hasCounter;
            },
        },
        props: {
            //value: {
            //    required: true
            //}
            isSingle: {
                default: false
            },
            solo: Boolean,
            soloInverted: Boolean,
            filled: Boolean,
            counter: [Boolean, Number, String],
            counterValue: Number,
            linkDialogEnabled: {
                type: Boolean,
                default: false
            },
            linkDialogButtonProps: Object
        },
        methods: {
            getSelectionState(isActive) {
                let res = [];
                if (isActive.bold())
                    res.push(0);
                if (isActive.italic())
                    res.push(1);

                return res;
            },
            genLabel() {
                if (!this.showLabel)
                    return null;

                const data = {
                    props: {
                        absolute: true,
                        color: this.validationState,
                        dark: this.dark,
                        disabled: this.isDisabled,
                        focused: !this.isSingle && (this.isFocused || !!this.validationState),
                        for: this.computedId,
                        //left: this.labelPosition.left,
                        light: this.light,
                        //right: this.labelPosition.right,
                        value: this.labelValue,
                    },
                }

                return this.$createElement(VLabel, data, this.$slots.label || this.label)
            },
            genBubbleMenu() {
                if (!this.isInteractive)
                    return null;

                return this.$createElement(VHtmlInputBubbleMenu, {
                    attrs: {
                        editor: this.editor,
                        linkDialogEnabled: this.linkDialogEnabled,
                        linkDialogButtonProps: this.linkDialogButtonProps
                    }
                })
            },
            genEditorContent() {
                return this.$createElement(EditorContent, {
                    //style: {},
                    staticClass: "editor__content",
                    attrs: {
                        editor: this.editor,
                    },
                    ref: "input",
                });
            },
            genEditor() {
                return this.$createElement("div", {
                    staticClass: "editor"
                }, [
                    this.genBubbleMenu(),
                    this.genEditorContent(),
                ]);
            },
            genDefaultSlot() {
                return this.$createElement("div", {
                    staticClass: "v-text-field__slot"
                }, [
                    this.genLabel(),
                    this.genEditor()
                ]);
            },
            genCounter() {
                var _ref;

                if (!this.hasCounter) return null;
                const max = this.counter === true ? this.attrs$.maxlength : this.counter;
                const props = {
                    dark: this.dark,
                    light: this.light,
                    max,
                    value: this.computedCounterValue
                };
                return (_ref = this.$scopedSlots.counter == null ? void 0 : this.$scopedSlots.counter({
                    props
                })) != null ? _ref : this.$createElement(VCounter, {
                    props
                });
            },
            genMessages() {
                if (!this.showDetails) return null;
                const messagesNode = VInput.options.methods.genMessages.call(this);
                const counterNode = this.genCounter();
                return this.$createElement('div', {
                    staticClass: 'v-text-field__details'
                }, [messagesNode, counterNode]);
            },
            onFocus() {
                this.isFocused = true;
            },
            onUpdate({ editor }) {
                const html = editor.getHTML();
                this.$emit("input", html === "<p></p>" ? null : html);
            },
            onBlur({ event }) {
                this.isFocused = false;
                event && this.$nextTick(() => this.$emit("blur", event));
            },
            setEditable() {
                this.editor.setOptions({
                    editable: this.isInteractive
                })
            },
            updateValue(val) {
                // Sets validationState from validatable
                this.hasColor = val

                //if (val) {
                //    this.initialValue = this.lazyValue
                //} else if (this.initialValue !== this.lazyValue) {
                //    this.$emit('change', this.lazyValue)
                //}
            }
        },
        watch: {
            isInteractive: {
                immediate: true,
                handler: function () { this.setEditable() }
            },
            value(newValue) {
                if (this.editor.getHTML() !== newValue)
                    this.editor.commands.setContent(newValue);
            },
            isFocused(newValue) { this.updateValue(newValue) }
        },
        mounted() {
            requestAnimationFrame(() => (this.isBooted = true));
        },
        beforeDestroy: function () {
            this.editor.destroy();
        }
    }
</script>
<style>
    .editor {
        position: relative;
        width: 100%;
    }

    .editor__content {
        min-height: 32px;
        padding-top: 5px;
    }

    .theme--light.v-input .editor__content {
        color: rgba(0, 0, 0, 0.87);
    }

    .theme--light.v-input--is-disabled .editor__content {
        color: rgba(0, 0, 0, 0.5);
    }

    .theme--dark.v-input .editor__content {
        color: #FFFFFF;
    }

    .theme--dark.v-input--is-disabled .editor__content {
        color: rgba(255, 255, 255, 0.5);
    }

    .ProseMirror {
        word-break: break-word;
    }

        .ProseMirror > p {
            margin-bottom: 20px;
        }

            .ProseMirror > p:last-child {
                margin-bottom: 6px;
            }

        .ProseMirror.ProseMirror-focused {
            outline: none;
        }
</style>