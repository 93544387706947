import Vue from "vue"
import VueRouter from "vue-router"

import store from "./store"
import api from "../api"

import { ArticleSource, RouteName } from "../enums"

import ViewHome from "./views/ViewHome.vue"
import ViewWelcome from "./views/ViewWelcome.vue"
import ViewNewArticle from "./views/ViewNewArticle.vue"
import ViewArticleSent from "./views/ViewArticleSent.vue"

import ViewChoosePublication from "../views/ViewChoosePublication.vue"
import ViewArticleChat from "../views/ViewArticleChat.vue"
import ViewArticleNotFound from "../views/ViewArticleNotFound.vue"


Vue.use(VueRouter);

const BusinessCodesByParam = {
    "jp" : "jp",
    "finans" : "jp",
    "pol" : "pol"
}
Object.freeze(BusinessCodesByParam);

const MediaNamesByParam = {
    "jp" : "jyllands-posten",
    "finans" : "finans",
    "pol" : "politiken"
}
Object.freeze(MediaNamesByParam);

const MediaParamsByName = {
    "jyllands-posten" : "jp",
    "finans" : "finans",
    "politiken" : "pol"
}
Object.freeze(MediaParamsByName);

const routes = [
    {
        path: "/",
        component: ViewChoosePublication
    },
    {
        path: "/chat/:uid",
        beforeEnter: (to, from, next) => {
            processGeneralChatRoute(to, from, next);
        }
    },
    {
        path: "/:media(jp|pol|finans)",
        name: RouteName.PublicationRoot,
        component: ViewHome,
        props: route => ({
            account: store.state.account,
            businessCode: BusinessCodesByParam[route.params.media.toLowerCase()],
            mediaName: MediaNamesByParam[route.params.media.toLowerCase()]
        }),
        beforeEnter: (to, from, next) => {
            processPublicationRoute(to, from, next);
        },
        children: [
            {
                path: "welcome",
                name: RouteName.Welcome,
                component: ViewWelcome
            },
            {
                path: "send",
                name: RouteName.NewArticle,
                component: ViewNewArticle,
                props: () => ({
                    articleSource: ArticleSource.Debate
                })
            },
            {
                path: "not-found",
                name: RouteName.ArticleNotFound,
                component: ViewArticleNotFound
            },
            {
                path: ":uid",
                name: RouteName.ArticleSent,
                component: ViewArticleSent,
                props: route => ({
                    uid: route.params.uid,
                    articleSource: ArticleSource.Debate
                })
            },
            {
                path: ":uid/chat",
                name: RouteName.ArticleChat,
                component: ViewArticleChat,
                props: route => ({
                    uid: route.params.uid,
                    articleSource: ArticleSource.Debate
                })
            }
        ]
    },
    {
        path: "*",
        redirect: "/"
    }
];

const processGeneralChatRoute = async (to, from, next) => {
    await store.loadAccount();
    if (!store.state.account.loggedIn) {
        api.autoLogin();
        return;
    }

    const uid = to.params.uid;
    const info = await api.getArticleInfo(uid);
    if (info.found === false)
        next({ path: "/" });
    else {
        const mediaParam = MediaParamsByName[info.mediaName.toLowerCase()];
        next({ name: RouteName.ArticleChat, params: { media: mediaParam, uid: uid }, replace: from.path !== "/" });
    }
}

//const politikenArticleUrl = "https://politiken.dk/debat/send/";

const processPublicationRoute = async (to, from, next) => {
    //const businessCode = BusinessCodesByParam[to.params.media.toLowerCase()];
    //if (businessCode === "pol" &&
    //    to.name !== RouteName.ArticleChat &&
    //    to.name !== RouteName.ArticleNotFound) {
    //    window.location = politikenArticleUrl;
    //    return;
    //}

    await store.loadAccount();

    const loggedIn = store.state.account.loggedIn;

    let redirectRouteName = null;
    switch (to.name) {
        case RouteName.PublicationRoot:
            //not logged in -> welcome, 
            //logged in -> new article
            redirectRouteName = loggedIn ? RouteName.NewArticle : RouteName.Welcome;
            break;
        case RouteName.Welcome:
            //logged in -> root
            if (loggedIn)
                redirectRouteName = RouteName.PublicationRoot;
            break;
        case RouteName.NewArticle:
            //not logged in -> root
            if (!loggedIn)
                redirectRouteName = RouteName.PublicationRoot;
            break;
        case RouteName.ArticleSent:
        case RouteName.ArticleChat:
            //not logged in -> redirect to login and return back
            //article not found, invalid url, not a sender, etc. -> article not found
            if (!loggedIn) {
                api.autoLogin();
                return;
            } else {
                const info = await api.getArticleInfo(to.params.uid);
                if (info.found === false)
                    redirectRouteName = RouteName.ArticleNotFound;
            }
            break;
        default:
    }
    if (redirectRouteName)
        next({ name: redirectRouteName, params: { media: to.params.media }, replace: from.path !== "/" });
    else
        next();
};


const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
