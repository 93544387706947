<template>
    <div v-if="article"
         class="text-break">
        <v-row no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.contentType"></span>:</p>
                <div v-text="article.contentTypeName"></div>
            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col>
                <p class="static-text-heading-3">Indsenders oplysninger:</p>
                <div>
                    <b>Navn: </b><span v-text="article.sender.name"></span>
                </div>
                <div>
                    <b>E-mailadresse: </b><span v-text="article.sender.email"></span>
                </div>
                <div>
                    <b>Telefonnummer: </b><span v-text="article.sender.phone"></span>
                </div>
            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.articleAuthorsHeader"></span>:</p>

                <template v-for="(author, index) in article.authors">
                    <div :key="index"
                         class="mb-4">
                        <label v-text="'#' + (index + 1)"></label>
                        <div>
                            <b>Navn: </b><span v-text="author.name"></span>
                        </div>
                        <div v-if="author.profession">
                            <b><span v-text="labels.authorProfession"></span>: </b><span v-text="author.profession"></span>
                        </div>
                        <div v-if="author.zipCode || author.city">
                            <b>Poster. + by: </b><span v-text="author.zipCode + ' ' + author.city"></span>
                        </div>
                        <div v-if="author.address">
                            <b>Vejnavn + nr.: </b><span v-text="author.address"></span>
                        </div>
                        <div v-if="author.gender">
                            <b>Køn: </b><span v-text="author.gender"></span>
                        </div>
                        <div v-if="author.birthDate">
                            <b>Fødselsår: </b><span v-text="getYear(author.birthDate)"></span>
                        </div>
                    </div>
                </template>

            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.articleFieldsHeader"></span>:</p>

                <div>
                    <b><span v-text="labels.articleTitle"></span>:</b>
                </div>
                <p v-text="article.title"></p>

                <template v-if="article.subtitle">
                    <div>
                        <b><span v-text="labels.articleSubtitle"></span>:</b>
                    </div>
                    <p v-text="article.subtitle"></p>
                </template>

                <template v-if="article.prefix">
                    <div>
                        <b><span v-text="labels.articlePrefix"></span>:</b>
                    </div>
                    <p v-text="article.prefix"></p>
                </template>

                <template v-if="article.frontPageTitle">
                    <div>
                        <b><span v-text="labels.articleFrontPageTitle"></span>:</b>
                    </div>
                    <p v-text="article.frontPageTitle"></p>
                </template>

                <div>
                    <b><span v-text="labels.articleText"></span>:</b>
                </div>
                <p v-html="article.text"></p>
            </v-col>
        </v-row>


        <v-row v-if="article.summary || article.comment"
               no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.articleInfoFieldsHeader"></span>:</p>

                <template v-if="article.summary">
                    <div>
                        <b>Mit budskab i indlægget er:</b>
                    </div>
                    <p v-text="article.summary"></p>
                </template>

                <template v-if="article.comment">
                    <div>
                        <b><span v-text="labels.articleComment"></span>?</b>
                    </div>
                    <p v-text="article.comment"></p>
                </template>
            </v-col>
        </v-row>


        <v-row v-if="articleFiles.length > 0 || uploadEnabled"
               no-gutters>
            <v-col>
                <p class="static-text-heading-3">Filer:</p>

                <template v-for="(articleFile, index) in articleFiles">
                    <div :key="index"
                         class="mb-4">
                        <div>
                            <label v-text="'#' + (index + 1)"></label>
                            <span v-if="uploadEnabled"
                                  class="mx-2">|</span>
                            <a v-if="uploadEnabled"
                               href="#"
                               @click.prevent="deleteArticleFile(articleFile.id)"
                               :class="getDisabledLinkClass()">- Slet</a>
                        </div>
                        <div>
                            <a :href="getArticleFileDownloadUrl(uid, articleFile.id)">
                                <span v-text="articleFile.fileName"></span>
                            </a>
                        </div>
                        <div v-if="articleFile.caption">
                            <b>Billedtekst: </b>
                            <span v-text="articleFile.caption"></span>
                        </div>
                        <div v-if="articleFile.photographer">
                            <b>Fotograf: </b>
                            <span v-text="articleFile.photographer"></span>
                        </div>
                        <div v-if="articleFile.copyright">
                            <b>Copyright: </b>
                            <span v-text="articleFile.copyright"></span>
                        </div>
                    </div>
                </template>

                <v-slide-y-transition v-if="uploadEnabled"
                                      :group="true">
                    <div v-for="(file, index) in files"
                         :key="index"
                         :index="index"
                         class="mb-4">
                        <div>
                            <label v-text="'#' + (index + 1 + articleFiles.length)"
                                   :class="[file.size > maxFileSize ? 'error--text' : null]">
                            </label>
                            <span class="mx-2">|</span>
                            <a href="#"
                               @click.prevent="files.splice(index, 1)"
                               :class="getDisabledLinkClass()">- Slet</a>
                        </div>
                        <div>
                            <span v-text="file.name"
                                  :class="file.size > maxFileSize ? 'error--text' : null"></span>
                        </div>

                        <div v-if="isImageFile(file) && contentType.enableImageCaption"
                             class="mt-2">
                            <label :for="'imageCaption' + index">Billedtekst</label>
                            <v-textarea v-model="file.caption"
                                        v-bind="mediaSetup.vuetify.textAreaProps"
                                        :rules="getTextValidationRules(imageFieldsRequirements.caption)"
                                        :id="'imageCaption' + index"
                                        rows="1"
                                        :maxlength="imageFieldsRequirements.caption.max">
                            </v-textarea>
                        </div>

                        <div v-if="isImageFile(file) && contentType.enableImagePhotographer"
                             class="mt-2">
                            <label :for="'imagePhotographer' + index">Fotograf</label>
                            <v-text-field v-model="file.photographer"
                                          v-bind="mediaSetup.vuetify.inputProps"
                                          :rules="getTextValidationRules(imageFieldsRequirements.photographer)"
                                          :id="'imagePhotographer' + index"
                                          :maxlength="imageFieldsRequirements.photographer.max">
                            </v-text-field>
                        </div>

                        <div v-if="isImageFile(file) && contentType.enableImageCopyright"
                             class="mt-2">
                            <label :for="'imageCopyright' + index">Copyright</label>
                            <v-text-field v-model="file.copyright"
                                          v-bind="mediaSetup.vuetify.inputProps"
                                          :rules="getTextValidationRules(imageFieldsRequirements.copyright)"
                                          :id="'imageCopyright' + index"
                                          :maxlength="imageFieldsRequirements.copyright.max">
                            </v-text-field>
                        </div>
                    </div>
                </v-slide-y-transition>

                <div v-if="uploadEnabled">
                    <input type="file"
                           ref="fileInput"
                           class="d-none"
                           accept=".doc,.docx,.odt,.rtf,.txt,.pdf,.bmp,.gif,.jpg,.jpeg,.jpe,.png,.psd,.tif,.tiff"
                           @change="addFiles(Array.from($refs.fileInput.files)); $refs.fileInput.value = ''" />
                    <a v-if="articleFiles.length < maxFiles && files.length === 0"
                       href="#"
                       @click.prevent="pendingSave ? null : $refs.fileInput.click()"
                       :class="['mr-4', getDisabledLinkClass()]">+ Indsend ny fil</a>
                    <a v-if="files.length > 0"
                       href="#"
                       @click.prevent="submitFiles"
                       :class="getDisabledLinkClass()">Indsend fil</a>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="article.facts.length > 0"
               no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.articleFacts"></span>:</p>

                <template v-for="(fact, index) in article.facts">
                    <div :key="index"
                         class="mb-4">
                        <label v-text="'#' + (index + 1)"></label>
                        <div>
                            <b><span v-text="labels.articleFactText"></span>: </b>
                        </div>
                        <p v-html="fact.text"></p>
                    </div>
                </template>
            </v-col>
        </v-row>


        <v-row v-if="article.reviews.length > 0"
               no-gutters>
            <v-col>
                <p class="static-text-heading-3"><span v-text="labels.articleReviews"></span>:</p>

                <template v-for="(review, index) in article.reviews">
                    <div :key="index"
                         class="mb-4">
                        <label v-text="'#' + (index + 1)"></label>
                        <div>
                            <b><span v-text="labels.articleReviewRating"></span>: </b><span v-text="review.rating"></span>
                        </div>
                        <div v-if="review.ratingSymbol">
                            <b><span v-text="labels.articleReviewSymbol"></span>: </b><span v-text="review.ratingSymbol"></span>
                        </div>
                        <div>
                            <b><span v-text="labels.articleReviewText"></span>: </b>
                        </div>
                        <p v-html="review.text"></p>
                    </div>
                </template>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import api from "../api"
    import { ArticleSource } from "../enums"
    import { imageFieldsRequirements, mapAndJoinFiles, isImageFile, getTextValidationRules } from "../mixins/articleFormCore"

    export default {
        data() {
            return {
                businessSetup: null,
                article: null,
                contentType: null,
                articleFiles: [],
                files: [],
                chat: null,

                maxFileSize: api.maxFileSize,
                maxFiles: api.maxFiles,
                pendingSave: false,
                imageFieldsRequirements: imageFieldsRequirements
            }
        },
        props: {
            uid: String,
            businessCode: String,
            articleSource: String,
            mediaSetup: Object,
            enableFileUpload: {
                type: Boolean,
                default: false
            },
            notificationHub: Object
        },
        computed: {
            authorsNames() {
                return this.article.authors.map(a => (a.name)).join(", ");
            },
            articleFileNames() {
                return this.articleFiles.map(a => (a.fileName)).join(", ");
            },
            imageFileExtensions() {
                if (!this.businessSetup)
                    return [];
                return this.businessSetup.imageFileExtensions.split(";");
            },
            labels() {
                switch (this.articleSource) {
                    case ArticleSource.Debate:
                        return {
                            contentType: "Indlæggets type",
                            authorPrefix: "DEBATTØR ",
                            authorProfession: "Profession/beskæftigelse",
                            articleTitle: "Forslag til overskrift",
                            articleSubtitle: "Forslag til underoverskrift",
                            articlePrefix: "Præfix",
                            articleFrontPageTitle: "Forsiderubrik",
                            articleText: "Indlæg",
                            articleComment: "Er der noget, redaktionen bør vide om dig eller indlægget",
                            articleAuthorsHeader: "Om debattøren",
                            articleFieldsHeader: "Indlægget",
                            articleInfoFieldsHeader: "Om indlægget",

                            articleFacts: this.businessSetup.cueLabelFacts,
                            articleFactText: this.businessSetup.cueLabelFactText,

                            articleReviews: this.businessSetup.cueLabelReviews,
                            articleReviewText: this.businessSetup.cueLabelReviewText,
                            articleReviewRating: this.businessSetup.cueLabelReviewRating,
                            articleReviewSymbol: this.businessSetup.cueLabelReviewSymbol
                        }
                    case ArticleSource.Freelance:
                        return {
                            contentType: "Artikeltype",
                            authorPrefix: "#",
                            authorProfession: "Titel",
                            articleTitle: this.businessSetup.cueLabelTitle,
                            articleSubtitle: this.businessSetup.cueLabelSubtitle,
                            articlePrefix: "Præfix",
                            articleFrontPageTitle: "Forsiderubrik",
                            articleText: this.businessSetup.cueLabelText,
                            articleComment: "Er der noget, redaktionen bør vide om artiklen",
                            articleAuthorsHeader: "Byline",
                            articleFieldsHeader: "Artiklen",
                            articleInfoFieldsHeader: "Info om artiklen",

                            articleFacts: this.businessSetup.cueLabelFacts,
                            articleFactText: this.businessSetup.cueLabelFactText,

                            articleReviews: this.businessSetup.cueLabelReviews,
                            articleReviewText: this.businessSetup.cueLabelReviewText,
                            articleReviewRating: this.businessSetup.cueLabelReviewRating,
                            articleReviewSymbol: this.businessSetup.cueLabelReviewSymbol
                        }
                }
                return null;
            },
            uploadEnabled() {
                return this.enableFileUpload && 
                    this.chat !== null && this.chat.active && 
                    this.contentType !== null && this.contentType.enableFileUpload;
            }
        },
        methods: {
            async load() {
                const businessSetupPromise = api.getBusinessSetup(this.businessCode);
                const articlePromise = api.getArticle(this.uid);
                const contentTypePromise = api.getArticleContentType(this.uid);
                const filesPromise = api.getArticleFiles(this.uid);
                const chatPromise = api.getChat(this.uid);

                const result = await Promise.all([businessSetupPromise, articlePromise, contentTypePromise, filesPromise, chatPromise]);

                this.businessSetup = result[0];
                this.article = result[1];
                this.contentType = result[2];
                this.articleFiles = result[3];
                this.chat = result[4];
            },
            getYear(birthDate) {
                return birthDate !== null ? birthDate.substr(0, 4) : null;
            },
            getArticleFileDownloadUrl(uid, fileId) {
                return api.getArticleFileDownloadUrl(uid, fileId);
            },
            async addFiles(files) {
                this.files = await mapAndJoinFiles([], files, this.maxFiles);
            },
            isImageFile(file) {
                return isImageFile(file.name, this.imageFileExtensions);
            },
            getTextValidationRules(requirements) {
                return getTextValidationRules(requirements);
            },
            getDisabledLinkClass() {
                return this.pendingSave ? "text--disabled v-btn--disabled" : null;
            },
            preprocessFiles() {
                if (!this.uploadEnabled) {
                    this.files = [];
                    return;
                }
                for (let i = 0; i < this.files.length; i++) {
                    const file = this.files[i];
                    const isImage = this.isImageFile(file);
                    if (!isImage || !this.contentType.enableImageCaption)
                        file.caption = null;
                    if (!isImage || !this.contentType.enableImagePhotographer)
                        file.photographer = null;
                    if (!isImage || !this.contentType.enableImageCopyright)
                        file.copyright = null;
                }
            },
            async submitFiles() {
                if (!this.uploadEnabled)
                    return;

                this.preprocessFiles();

                try {
                    this.pendingSave = true;
                    for (let i = 0; i < this.files.length; i++) {
                        try {
                            await api.uploadArticleFile(this.uid, this.files[i]);
                        }
                        catch (e) {
                            console.log(`Error uploading file: ${e.message}`);
                        }
                    }
                    this.articleFiles = await api.getArticleFiles(this.uid);
                    this.files = [];
                }
                finally {
                    this.pendingSave = false;
                }
            },
            async deleteArticleFile(fileId) {
                if (!this.uploadEnabled)
                    return;

                try {
                    this.pendingSave = true;
                    try {
                        await api.deleteArticleFile(this.uid, fileId);
                    }
                    catch (e) {
                        console.log(`Error deleting file: ${e.message}`);
                    }
                    this.articleFiles = await api.getArticleFiles(this.uid);
                    this.files = [];
                }
                finally {
                    this.pendingSave = false;
                }
            },
            onChatChange(chat) {
                this.chat = chat;
            }
        },
        created() {
            this.load();
            this.notificationHub.on("chatChange", this.onChatChange);
        },
        beforeDestroy() {
            this.notificationHub.off("chatChange", this.onChatChange);
        }
    }
</script>
