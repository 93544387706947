import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: true
    //    themes: {
    //        light: {
    //            primary: colors.purple,
    //            secondary: colors.grey.darken1,
    //            accent: colors.shades.black,
    //            error: colors.red.accent3,
    //            background: colors.indigo.lighten5
    //        },
    //        dark: {
    //            primary: colors.blue.lighten3,
    //            background: colors.indigo.base
    //        }
    //    }
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    }
});
