<template>
    <v-container>
        <div class="form-section">
            <v-row no-gutters>
                <div>
                    <p class="static-text-heading-2">Fejl</p>
                    <p class="static-text-body-1">Artikel ikke fundet.</p>
                </div>
            </v-row>
        </div>
    </v-container>
</template>


<script>
    export default {
        props: {
            account: Object,
            businessCode: String,
            mediaName: String,
            mediaSetup: Object
        }
    }
</script>