export default {
   methods: {
        splitByNewLine(text) {
            if (!text)
                return [];
            return text.split(/\n/g);
        },
        trimByLength(value, maxLength) {
            if (value && value.length > maxLength)
                return value.substring(0, maxLength).trim() + "…";
            return value;
        }
   }
}