<template>
    <div v-if="chat">
        <div class="chat-messages">
            <v-container v-if="chatMessages !== null">
                <template v-for="(chatMessage, index) in sortedChatMessages">
                    <v-row v-if="isDateSeparatorNeeded(index)"
                           :key="'sep' + chatMessage.id"
                           class="date-separator"
                           dense>
                        <v-col sm="2"
                               class="hidden-xs-only"></v-col>
                        <v-col class="d-flex align-center">
                            <v-divider></v-divider>
                            <div class="px-3"
                                 v-text="formatSeparatorDate(chatMessage.created)"></div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col sm="2"
                               class="hidden-xs-only"></v-col>
                    </v-row>

                    <v-row :key="'row' + chatMessage.id"
                           dense>
                        <v-col :class="getChatMessageClass(chatMessage)">
                            <v-avatar :color="getAvatarColor(chatMessage)"
                                      size="36"
                                      class="ma-2">
                                <span class="white--text text-uppercase font-weight-medium"
                                      v-text="getInitials(chatMessage)"></span>
                            </v-avatar>

                            <v-sheet elevation="0"
                                     rounded
                                     class="px-4 py-2">
                                <div class="caption mb-1">
                                    <span v-text="formatWithCustomPattern(chatMessage.created, 'HH:mm')"></span>
                                    <span>, <span class="font-weight-bold" v-text="getChatMessagePersonName(chatMessage)"></span></span>
                                </div>
                                <div v-for="(line, lineIndex) in splitByNewLine(chatMessage.text)"
                                     :key="lineIndex">
                                    <span v-if="line"
                                          v-text="line"></span>
                                    <br v-else />
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>

                </template>
                <div ref="scrollAnchor"></div>
            </v-container>
        </div>

        <v-divider></v-divider>

        <div class="py-1">
            <v-textarea v-if="chat.active"
                        v-model="newChatMessageText"
                        v-bind="mediaSetup.vuetify.textAreaProps"
                        :solo="false"
                        :placeholder="$t('writeMessage')"
                        :readonly="pendingSend"
                        class="v-textarea--chat-new-message"
                        ref="newChatMessageInput">
                <template v-slot:append-outer>
                    <v-btn icon
                           color="primary"
                           :disabled="!newChatMessageText || pendingSend"
                           @click="send">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template>
            </v-textarea>

            <v-alert v-else
                     dense
                     outlined
                     width="100%"
                     border="left"
                     class="mt-4">
                <span v-text="$t('chatIsClosed')"></span>
            </v-alert>
        </div>
    </div>
</template>

<script>
    import api from "../api"

    import ChatCommon from "@/mixins/chatCommon";
    
    export default {
        mixins: [ChatCommon],
        props: {
            mediaSetup: Object
        },
        methods: {
            async load() {
                await this.loadData();
                this.scrollToBottom();
            },
            getChatMessageClass(chatMessage) {
                return [
                    "d-flex chat-message",
                    chatMessage.editorName ? "flex-row" : "flex-row-reverse",
                    chatMessage.editorName ? null : "chat-message--mine",
                    !this.chat.active ? "chat-message--disabled" : null
                ]
            },

            getChat(articleId) {
                return api.getChat(articleId)
            },
            getChatMessages(articleId) {
                return api.getChatMessages(articleId)
            },
            createChatMessage(articleId, messageText) {
                return api.createChatMessage(articleId, messageText)
            }
        },
        created() {
            this.load();
        }
    }
</script>
<style>
    .chat-messages {
        max-height: calc(100vh - 400px);
        overflow: auto;
        overflow-wrap: break-word;
    }

        .chat-messages .chat-message > .v-sheet {
            max-width: 600px;
        }

        .chat-messages .chat-message.chat-message--disabled {
            opacity: 0.6;
        }

        .chat-messages .date-separator {
            font-size: 0.875rem;
        }


    .theme--light .chat-messages .chat-message > .v-sheet {
        background-color: #F5F5F5;
    }

    .theme--light .chat-messages .chat-message.chat-message--mine > .v-sheet {
        background-color: #E8EAF6;
    }


    .theme--dark .chat-messages .chat-message.chat-message--mine > .v-sheet {
        background-color: #546E7A;
    }

    .theme--dark .chat-messages .chat-message > .v-sheet {
        background-color: #616161;
    }
</style>