import Vue from "vue"
import router from "./router"
import vuetify from "@/plugins/vuetify"
import { VBtn, VMenu, VList, VListItem, VListItemTitle, VDivider, VIcon } from "vuetify/lib"
import VHtmlInput from "@/components/VHtmlInput"
import VNumberInput from "@/components/VNumberInput"

import i18n from "@/i18n"

import App from "./App.vue"

import "../styles.css"
import "../branding/jp/styles.css"
import "../branding/jp/jyllands-posten/styles.css"
import "../branding/jp/finans/styles.css"
import "../branding/politiken/styles.css"

Vue.config.productionTip = false;

i18n.locale = "da";

//Global component registration for functional components
Vue.component("VBtn", VBtn);
Vue.component("VMenu", VMenu);
Vue.component("VList", VList);
Vue.component("VListItem", VListItem);
Vue.component("VListItemTitle", VListItemTitle);
Vue.component("VDivider", VDivider);
Vue.component("VIcon", VIcon);
Vue.component("VHtmlInput", VHtmlInput);
Vue.component("VNumberInput", VNumberInput);


new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");