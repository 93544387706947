<template>
    <v-container>
        <div class="form-section">
            <component :is="mediaName + 'StaticArticleSentHeader'"
                       :media-setup="mediaSetup"></component>
        </div>

        <div class="shadow-divider shadow-divider--up"></div>

        <div class="form-section form-section--article-info mb-10">
            <p class="static-text-heading-2 mb-1">Dine indsendte oplysninger</p>
            <div>Nedenfor finder du en oversigt over de oplysninger, vi har modtaget fra dig.</div>
        </div>

        <div class="form-section">
            <v-article-info :uid="uid"
                            :business-code="businessCode"
                            :media-setup="mediaSetup"
                            :article-source="articleSource"
                            :notification-hub="notificationHub">
            </v-article-info>
        </div>
    </v-container>
</template>

<script>
    import connection from "../../signalr";
    
    import jyllandsPostenStaticArticleSentHeader from "../../branding/jp/jyllands-posten/components/VStaticArticleSentHeader"
    import finansStaticArticleSentHeader from "../../branding/jp/finans/components/VStaticArticleSentHeader"
    import politikenStaticArticleSentHeader from "../../branding/politiken/components/VStaticArticleSentHeader"

    import VArticleInfo from "../../components/VArticleInfo"

    export default {
        components: {
            jyllandsPostenStaticArticleSentHeader,
            finansStaticArticleSentHeader,
            politikenStaticArticleSentHeader,
            VArticleInfo
        },
        props: {
            account: Object,
            businessCode: String,
            mediaName: String,
            mediaSetup: Object,
            uid: String,
            articleSource: String
        },
        data() {
            return {
                notificationHub: connection
            }
        }
    }
</script>