import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";

import DateFormat from "@/mixins/dateformat";
import TextUtils from "@/mixins/textUtils";

export default {
    mixins: [DateFormat, TextUtils],
    data() {
        return {
            chat: null,
            chatMessages: null,
            newChatMessageText: null,
            pendingSend: false,
            errorSend: null
        }
    },
    props: {
        articleId: {
            type: [Number, String],
            required: true
        },
        notificationHub: {
            type: Object,
            required: true
        }
    },
    computed: {
        sortedChatMessages() {
            if (!this.chatMessages)
                return [];
            return [...this.chatMessages].sort((a, b) => {
                if (a.created === b.created)
                    return 0;
                return a.created > b.created ? 1 : -1;
            });
        },
        personInitials() {
            return this.chatMessages.reduce((acc, cm) => {
                const name = this.getChatMessagePersonName(cm);
                if (!Object.prototype.hasOwnProperty.call(acc, name)) {
                    const parts = name.split(/\s/).filter(p => p.length > 0);
                    acc[name] = parts.length === 1 ? parts[0][0] : parts[0][0] + parts[1][0];
                }
                return acc;
            }, {});
        }
    },
    methods: {
        getAvatarColor(chatMessage) {
            const name = this.getChatMessagePersonName(chatMessage);
            return this.stringToHslColor(name);
        },
        getChatMessagePersonName(chatMessage) {
            return chatMessage.editorName ?? this.chat.senderName;
        },
        stringToHslColor(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            const h = hash % 360;
            return `hsl(${h}, 30%, 60%)`;
        },
        getInitials(chatMessage) {
            return this.personInitials[this.getChatMessagePersonName(chatMessage)];
        },
        scrollToBottom() {
            this.$nextTick(
                () => { if (this.$refs.scrollAnchor) this.$refs.scrollAnchor.scrollIntoView(); }
            );
        },
        isDateSeparatorNeeded(index) {
            if (index === 0)
                return true;

            const created = parseISO(this.sortedChatMessages[index].created);
            const prevCreated = parseISO(this.sortedChatMessages[index - 1].created);
            return !isSameDay(created, prevCreated);
        },
        formatSeparatorDate(created) {
            return this.formatWithCustomPattern(created, "eeee, dd-MM-yyyy");
        },
        async loadData() {
            const chatPromise = this.getChat(this.articleId);
            const chatMessagesPromise = this.getChatMessages(this.articleId);

            [this.chat, this.chatMessages] = await Promise.all([chatPromise, chatMessagesPromise]);
        },
        async send() {
            if (this.pendingSend)
                return;

            if (this.newChatMessageText) {
                try {
                    this.pendingSend = true;
                    this.errorSend = null;

                    const chatMessage = await this.createChatMessage(this.articleId, this.newChatMessageText);
                    this.onChatMessageChange(chatMessage);
                    this.newChatMessageText = null;
                } catch (e) {
                    this.errorSend = e.message;
                } finally {
                    this.pendingSend = false;
                }
            }
        },
        onChatChange(chat) {
            this.chat = chat;
        },
        onChatMessageChange(chatMessage) {
            const index = this.chatMessages.findIndex(cm => cm.id === chatMessage.id);
            if (index < 0)
                this.chatMessages.push(chatMessage);
            else
                this.$set(this.chatMessages, index, chatMessage);
            this.scrollToBottom();
        }
    },
    created() {
        //Component should contain next methods
        //async getChat(articleId) - gets the chat object
        //async getChatMessages(articleId) - gets the list of messages
        //async createChatMessage(articleId, messageText) - creates a new chat message
        if (typeof this.getChat !== "function" ||
            typeof this.getChatMessages !== "function" ||
            typeof this.createChatMessage !== "function")
            throw new Error("Missing required methods implementation in the component");

        this.notificationHub.on("chatChange", this.onChatChange);
        this.notificationHub.on("chatMessageChange", this.onChatMessageChange);
    },
    beforeDestroy() {
        this.notificationHub.off("chatChange", this.onChatChange);
        this.notificationHub.off("chatMessageChange", this.onChatMessageChange);
    }
}